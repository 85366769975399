import React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

interface IProps {
  chapterNumber: number
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.14em',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  textTransform: 'uppercase',
  paddingRight: '24px',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    paddingLeft: 0
  }
}))

const ChapterChip = (props: IProps) => {
  const { chapterNumber } = props

  return <StyledChip label={`Chapter ${chapterNumber}`} />
}

export default ChapterChip
