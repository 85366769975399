import React from 'react'
import { Typography } from '@mui/material'

interface Props {
  text: string
  searchInput?: string
  type: 'title' | 'blurb'
}

const HighlightText = ({ text, searchInput, type }: Props) => {
  const words = text.split(' ')

  const splitWordToHighlightSearch = (word: string, searchInput: string) => {
    let beforeHighlight
    let highlightedSection
    let afterHighlight

    for (let i = 0; i < word.length; i++) {
      if (
        word.slice(i, i + searchInput.length).toLowerCase() ===
        searchInput.toLowerCase()
      ) {
        highlightedSection = word.slice(i, i + searchInput.length)
        beforeHighlight = word.slice(0, i)
        afterHighlight = word.slice(i + searchInput.length)
        break
      }
    }
    return [beforeHighlight, highlightedSection, afterHighlight]
  }

  return (
    <Typography
      variant='h5'
      sx={{
        fontSize: type === 'title' ? '20px' : '14px',
        lineHeight: type === 'blurb' ? '20px' : '28px',
        fontWeight: 400
      }}
    >
      {searchInput &&
        words.map((word, index) => {
          const [beforeHighlight, highlightedSection, afterHighlight] =
            splitWordToHighlightSearch(word, searchInput)
          if (word.toLowerCase().includes(searchInput.toLowerCase())) {
            return (
              <span key={index}>
                {beforeHighlight && `${beforeHighlight}`}
                <b>
                  <u>{`${highlightedSection}`}</u>
                </b>
                {afterHighlight ? `${afterHighlight} ` : ' '}
              </span>
            )
          } else {
            return `${word} `
          }
        })}
    </Typography>
  )
}

export default HighlightText
