import React from 'react'
import { ArrowRightIcon } from 'components/SharedComponents'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: 'black',
  padding: '21px 18px',
  '&:hover': {
    backgroundColor: 'black'
  }
}))

const RightArrowButton = ({ ...props }: IconButtonProps) => {
  return (
    <StyledIconButton onClick={props.onClick}>
      <ArrowRightIcon />
    </StyledIconButton>
  )
}
export default RightArrowButton
