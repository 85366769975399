import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const UpTriangleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='11' height='5' viewBox='0 0 11 5' fill='none' {...props}>
      <path
        d='M10.4575 5L5.45752 -4.37114e-07L0.45752 5L10.4575 5Z'
        fill='black'
      />
    </SvgIcon>
  )
}

export default UpTriangleIcon
