import { gql } from '@apollo/client'
import { responsiveImageFragment } from '../fragments'

export const HOW_TOS_QUERY = gql`
  query Interviews {
    allHowTos {
      slug
      category {
        slug
      }
    }
  }
`

export const HOW_TO_QUERY = gql`
  query HowTo($slug: String) {
    howTo(filter: { slug: { eq: $slug } }) {
      title
      slug
      contentBucket {
        slug
      }
      category {
        slug
      }
      minutes
      pdf {
        url
        alt
      }
      content {
        value
        blocks {
          __typename
          ... on FactoidRecord {
            id
            fact
            source
          }
          ... on CalloutComponentRecord {
            id
            backgroundColor
            content {
              value
            }
          }
          ... on CalloutListComponentRecord {
            id
            backgroundColor
            title
            listItem {
              content {
                value
              }
            }
          }
          ... on ImageBlockRecord {
            id
            fullSizeImage
            image {
              url
              responsiveImage(imgixParams: { fm: jpg }) {
                ...responsiveImageFragment
              }
            }
          }
          ... on ListBlockRecord {
            id
            title
            subtitle
            checklist
            item {
              listItemContent {
                value
              }
            }
          }
        }
      }
    }
  }
  ${responsiveImageFragment}
`

export const INTERVIEWS_QUERY = gql`
  query Interviews {
    allInterviews {
      slug
      category {
        slug
      }
    }
  }
`

export const INTERVIEW_QUERY = gql`
  query Interview($slug: String) {
    interview(filter: { slug: { eq: $slug } }) {
      title
      slug
      contentBucket {
        slug
      }
      category {
        slug
      }
      minutes
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            image {
              responsiveImage(
                imgixParams: { fm: jpg, fit: crop, w: 865, h: 575 }
              ) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }
    }
  }
  ${responsiveImageFragment}
`

export const EVENTS_QUERY = gql`
  query Events {
    allEvents {
      slug
      externalUrl
      category {
        slug
      }
    }
  }
`

export const EVENT_QUERY = gql`
  query Event($slug: String) {
    event(filter: { slug: { eq: $slug } }) {
      title
      slug
      contentBucket {
        slug
      }
      category {
        slug
      }
      startDate
      onDemand
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            image {
              responsiveImage(
                imgixParams: { fm: jpg, fit: crop, w: 865, h: 575 }
              ) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }
    }
  }
  ${responsiveImageFragment}
`

export const TOOLKITS_QUERY = gql`
  query Toolkits {
    allToolkits {
      slug
      category {
        slug
      }
      slides {
        slug
      }
    }
  }
`

export const TOOLKIT_QUERY = gql`
  query Toolkit($slug: String) {
    toolkit(filter: { slug: { eq: $slug } }) {
      title
      slug
      blurb
      minutes
      category {
        slug
      }
      contentBucket {
        slug
      }
      pdf {
        url
        alt
      }
      image {
        url
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 865, h: 575 }) {
          ...responsiveImageFragment
        }
      }
      slides {
        id
        slug
        title
        eyebrow
        pdf {
          url
          alt
        }
        slideNumber
        content {
          value
          blocks {
            __typename
            ... on ImageBlockRecord {
              id
              fullSizeImage
              image {
                url
                responsiveImage(imgixParams: { fm: jpg }) {
                  ...responsiveImageFragment
                }
              }
            }
            ... on CalloutComponentRecord {
              id
              backgroundColor
              content {
                value
              }
            }
            ... on CalloutListComponentRecord {
              id
              backgroundColor
              title
              listItem {
                content {
                  value
                }
              }
            }
            ... on ListBlockRecord {
              id
              title
              subtitle
              checklist
              item {
                listItemContent {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  ${responsiveImageFragment}
`

export const VIDEOS_QUERY = gql`
  query Videos {
    allVideos {
      slug
      category {
        slug
      }
    }
  }
`

export const VIDEO_QUERY = gql`
  query Video($slug: String) {
    video(filter: { slug: { eq: $slug } }) {
      slug
      title
      minutes
      blurb
      heroTitle
      heroSubtitle
      image {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 1100, h: 700 }) {
          ...responsiveImageFragment
        }
      }
      externalVideo {
        provider
        providerUid
        thumbnailUrl
        url
      }
      video {
        video {
          mp4Url(res: high)
          thumbnailUrl
        }
      }
      contentBucket {
        slug
      }
      category {
        slug
      }
      minutes
      tags
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            image {
              responsiveImage(
                imgixParams: { fm: jpg, fit: crop, w: 865, h: 575 }
              ) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }
      transcript {
        minuteMark
        secondsMark
        copy {
          value
        }
      }
    }
  }
  ${responsiveImageFragment}
`

export const INSIGHT_QUERY = gql`
  query CommunityInsight($slug: String) {
    communityInsight(filter: { slug: { eq: $slug } }) {
      title
      slug
      blurb
      contentBucket {
        slug
      }
      collection
      minutes
      pdf {
        url
        alt
      }
      tags
      image {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 1100, h: 700 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            fullSizeImage
            image {
              url
              responsiveImage(imgixParams: { fm: jpg }) {
                srcSet
                webpSrcSet
                sizes
                src
                width
                height
                aspectRatio
                alt
                title
                base64
              }
            }
          }
        }
      }
    }
  }
`

export const THOUGHT_LEADERSHIPS_QUERY = gql`
  query ThoughtLeaderships {
    allThoughtLeaderships {
      slug
      category {
        slug
      }
    }
  }
`

export const THOUGHT_LEADERSHIP_QUERY = gql`
  query ThoughtLeadership($slug: String) {
    thoughtLeadership(filter: { slug: { eq: $slug } }) {
      title
      slug
      blurb
      contentBucket {
        slug
      }
      minutes
      pdf {
        url
        alt
      }
      tags
      image {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 1100, h: 700 }) {
          srcSet
          webpSrcSet
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            fullSizeImage
            image {
              url
              responsiveImage(imgixParams: { fm: jpg }) {
                srcSet
                webpSrcSet
                sizes
                src
                width
                height
                aspectRatio
                alt
                title
                base64
              }
            }
          }
        }
      }
    }
  }
`
