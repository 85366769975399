import React from 'react'
import CtaButton from '../CtaButton/CtaButton'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const CtaContainer = styled('div')(() => ({
  paddingBottom: '24px'
}))

const StyledBox = styled(Box)<BoxProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  fontSize: '18px',
  fontStyle: 'italic',
  maxWidth: '400px',
  lineHeight: '1.625rem',
  letterSpacing: '-0.5px',
  paddingTop: '20px'
}))

const BookCTA = () => {
  return (
    <CtaContainer>
      <StyledBox>
        <img
          width={300}
          height={240}
          src='https://www.datocms-assets.com/54533/1634921691-bookctaimage.png'
          alt='Hiring For Diversity on Amazon'
        />
        <Typography
          variant='body1'
          sx={{ paddingBottom: '24px', width: '300px' }}
        >
          If you are looking for specific, actionable and practical advice to
          attract and engage diverse teams, “Hiring for Diversity” will hit the
          mark.
        </Typography>
        <Typography variant='body1'>Kathi Endere, Phd</Typography>
        <Typography variant='body1' sx={{ paddingBottom: '20px' }}>
          Vice President, Research
        </Typography>
      </StyledBox>
      <CtaButton
        variant='contained'
        onClick={() =>
          window.open(
            'https://www.amazon.com/Hiring-Diversity-Inclusive-Equitable-Organization/dp/1119800900/ref=sr_1_3',
            '_blank'
          )
        }
      >
        Buy the book
      </CtaButton>
    </CtaContainer>
  )
}

export default BookCTA
