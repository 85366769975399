import { gql } from '@apollo/client'

export const AUDIENCES_QUERY = gql`
  query Audiences {
    allAudiences {
      id
      name
    }
  }
`
