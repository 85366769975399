/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import * as React from 'react'
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  MenuItemProps,
  FormControl,
  Select,
  Typography,
  Button,
  Grid
} from '@mui/material'
import { Color } from 'components'
import { styled } from '@mui/material/styles'

interface Props {
  name: string
  filterValues: string[]
  selection?: string[]
  setSelection: React.Dispatch<any>
  isSearchResult?: boolean
}

interface FilterItemProps extends MenuItemProps {
  isSearchResult?: boolean
}

const FilterItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'isSearchResult'
})<FilterItemProps>(({ isSearchResult }) => ({
  backgroundColor: `${Color.grey200} !important`,
  borderRadius: '16px',
  width: 'max-content',
  height: 'auto',
  marginRight: isSearchResult ? '8px' : '6px',
  marginBottom: '12px',
  '&.Mui-selected': {
    backgroundColor: `${Color.main} !important`,
    color: 'white'
  }
}))
const Filter = ({
  name,
  filterValues,
  selection,
  setSelection,
  isSearchResult
}: Props) => {
  const handleChange = (event: any) => {
    const {
      target: { value }
    } = event
    setSelection(typeof value === 'string' ? value.split(',') : value)
  }

  const handleClearAll = () => {
    setSelection([])
  }
  return (
    <div>
      <FormControl
        sx={{
          marginRight: isSearchResult ? 0 : 3,
          width: isSearchResult ? '100%' : 300,
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: Color.grey50,
            border: `1px solid ${Color.grey300}`
          },
          '& fieldset': {
            border: 'none'
          }
        }}
      >
        <InputLabel id='label'>{name}</InputLabel>
        <Select
          multiple
          labelId='label'
          value={selection}
          onChange={handleChange}
          input={<OutlinedInput label={name} />}
          renderValue={(values: string[]) => (
            <Typography variant='body16'>{values.join(', ')}</Typography>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 66,
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            PaperProps: {
              sx: {
                maxWidth: 289,
                '& .MuiList-root': {
                  display: 'inline-flex',
                  flexWrap: 'wrap',
                  padding: '16px 6px 10px 16px'
                }
              }
            }
          }}
        >
          {filterValues.map((value: string, index: number) => (
            <FilterItem
              key={index}
              value={value}
              isSearchResult={isSearchResult}
              disableRipple
            >
              <Typography
                variant={selection?.includes(value) ? 'body14' : 'body14Light'}
              >
                {value}
              </Typography>
            </FilterItem>
          ))}
          <Grid container>
            <Button
              sx={{
                textDecoration: 'underline',
                '&:hover': {
                  backgroundColor: 'unset',
                  textDecoration: 'underline'
                },
                paddingTop: '8px',
                padding: '10px',
                display: 'block'
                // width: '100%'
              }}
              disableRipple
              disableTouchRipple
              onClick={handleClearAll}
            >
              <Typography variant='body2Medium'>Clear all</Typography>
            </Button>
          </Grid>
        </Select>
      </FormControl>
    </div>
  )
}
export default Filter
