import React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

interface ContentTypeChipProps {
  label: string
  backgroundColor: string
}

interface StyledChipProps extends ChipProps {
  backgroundColor: string
}

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== 'backgroundColor'
})<StyledChipProps>(({ backgroundColor }) => ({
  color: '#000000',
  backgroundColor,
  fontSize: 12,
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  textTransform: 'uppercase',
  padding: '4px 16px',
  height: '24px',
  borderRadius: '25px',
  lineHeight: '16px',
  letterSpacing: '0.17em',
  '& .MuiChip-label': {
    padding: '0'
  }
}))

export const ContentTypeChip = ({
  label,
  backgroundColor
}: ContentTypeChipProps) => {
  return <StyledChip backgroundColor={backgroundColor} label={label} />
}
