import React from 'react'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import PageEyebrow from 'components/PageEyebrow/PageEyebrow'
import StructuredTextContainer from '../StructuredTextContainer/StructuredTextContainer'
import { SlideContainerProps } from '../../../models'
import SlideButtonGroup from '../../SlideButtonGroup/SlideButtonGroup'

const SlideContainer = (props: SlideContainerProps) => {
  const {
    title,
    time,
    label,
    bgColor,
    content,
    pdfUrl,
    slides,
    currentSlide,
    baseToolkitUrl,
    categoryPageUrl
  } = props
  return (
    <Box
      sx={{
        maxWidth: '1460px'
      }}
    >
      <Grid container>
        <Grid item sm={6}>
          <PageEyebrow
            label={label}
            backgroundColor={bgColor}
            time={time}
            isVideo={false}
            pdfUrl={pdfUrl}
            childPdfUrl={currentSlide?.pdf?.url || undefined}
          />
          <Typography
            variant='h1'
            sx={{
              fontSize: '36px',
              lineHeight: '40px',
              letterSpacing: '-0.02em',
              paddingBottom: baseToolkitUrl ? '24px' : '30px'
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            backgroundColor: 'white',
            padding: '56px 20% 0 20%',
            border: '1px solid #CCCCCC',
            borderBottom: 'none',
            borderRadius: '8px 8px 0 0'
          }}
          sm={12}
          container
        />
        <Grid
          item
          sx={{
            backgroundColor: 'white',
            borderRight: '1px solid #CCCCCC',
            borderLeft: '1px solid #CCCCCC'
          }}
          sm={12}
          container
        >
          {currentSlide &&
          (currentSlide.eyebrow || currentSlide.title) &&
          currentSlide.title?.replace(/\s/g, '').toLowerCase() !== 'intro' ? (
            <Grid
              className='slide-title-container'
              sx={{ margin: '0 10% 16px 10%' }}
              container
            >
              {currentSlide.eyebrow ? (
                <Grid
                  className='slide-eyebrow'
                  sx={{ paddingBottom: '16px' }}
                  item
                  xs={12}
                >
                  <Typography
                    variant='h6'
                    sx={{
                      margin: '0 3% 0 3%',
                      fontSize: '20px',
                      lineHeight: '28px',
                      letterSpacing: '0.17em'
                    }}
                  >
                    {currentSlide.eyebrow}
                  </Typography>
                </Grid>
              ) : null}
              {currentSlide.title &&
              currentSlide.title.replace(/\s/g, '').toLowerCase() !==
                'intro' ? (
                <Grid className='slide-title' item xs={12}>
                  <Typography variant='h5' sx={{ margin: '0 3% 0 3%' }}>
                    {currentSlide.title}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          <StructuredTextContainer
            // @ts-ignore
            content={currentSlide?.content || content}
            isSlide={true}
          />
          {currentSlide ? (
            <>
              <Grid
                sx={{ maxWidth: '80%', margin: '0 auto' }}
                item
                xs={12}
                paddingTop='32px'
              >
                <Divider
                  sx={{
                    backgroundColor: '#000',
                    margin: '0 12.5%'
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: '32px', margin: '0 12.5%' }}>
                <SlideButtonGroup
                  slides={slides}
                  currentSlide={currentSlide}
                  baseUrl={baseToolkitUrl}
                  categoryPageUrl={categoryPageUrl}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid
          item
          sx={{
            backgroundColor: 'white',
            padding: '40px 9% 40px 11%',
            border: '1px solid #CCCCCC',
            borderTop: 'none',
            borderRadius: '0 0 8px 8px'
          }}
          sm={12}
          container
        />
      </Grid>
    </Box>
  )
}
export default SlideContainer
