/* eslint-disable react/require-default-props */
import React from 'react'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Slide } from '../../models'
import LeftArrowButton from '../LeftArrowButton/LeftArrowButton'
import RightArrowButton from '../RightArrowButton'
import CtaButton from '../CtaButton/CtaButton'
import SkipButton from './SkipButton'

interface Props {
  slides?: Slide[]
  currentSlide: Slide
  baseUrl?: string
  categoryPageUrl?: string
}

const SlideButtonGroup = ({
  slides,
  currentSlide,
  baseUrl,
  categoryPageUrl
}: Props) => {
  const navigate = useNavigate()
  const nextSlide =
    slides &&
    slides.find(slide => slide.slideNumber === currentSlide.slideNumber + 1)
  const prevSlide =
    slides &&
    slides.find(slide => slide.slideNumber === currentSlide.slideNumber - 1)
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      wrap='nowrap'
    >
      <Grid item container xs={6} alignItems='center'>
        {prevSlide !== undefined && (
          <Grid item marginRight={2}>
            <LeftArrowButton
              disabled={prevSlide === undefined}
              disableRipple
              onClick={() =>
                navigate(`/knowledge-center${baseUrl}/slide/${prevSlide?.slug}`)
              }
            />
          </Grid>
        )}

        <Grid item xs>
          {slides && slides.length > 1 && (
            <SkipButton
              slides={slides}
              currentSlide={currentSlide}
              onSlideChoose={slug =>
                navigate(`/knowledge-center${baseUrl}/slide/${slug}`)
              }
            >
              Skip to slide
            </SkipButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6} textAlign='right'>
        {/* //TODO Removed for now until design system changes are ready 
        <SaveExitButton
          disableRipple
          onClick={() => {
            navigate(`${categoryPageUrl}`)
          }}
        >
          Save and exit
        </SaveExitButton> */}
        {nextSlide ? (
          <RightArrowButton
            disableRipple
            onClick={() => {
              navigate(`/knowledge-center${baseUrl}/slide/${nextSlide.slug}`)
            }}
          >
            Next slide
          </RightArrowButton>
        ) : (
          <CtaButton
            variant='contained'
            disableRipple
            onClick={() => {
              navigate(`/knowledge-center${categoryPageUrl}`)
            }}
          >
            Done
          </CtaButton>
        )}
      </Grid>
    </Grid>
  )
}

export default SlideButtonGroup
