import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface CmsLinkProps {
  url: string
  children: ReactNode
}

const CmsLinkWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  '&:hover': {
    cursor: 'pointer'
  },
  a: {
    display: 'flex',
    textDecoration: 'none',
    width: '100%'
  }
}))

const StyledLink = styled(Link)(() => ({
  textDecoration: 'none'
}))

const CmsLink = ({ url, children }: CmsLinkProps) => {
  const externalUrl: boolean = url.includes('http')
  return (
    <CmsLinkWrapper>
      {externalUrl ? (
        <a href={url} target='_blank' rel='noreferrer'>
          {children}
        </a>
      ) : (
        <StyledLink to={url}>{children}</StyledLink>
      )}
    </CmsLinkWrapper>
  )
}
export default CmsLink
