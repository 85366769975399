/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import './index.css'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import App from './App'
import { ThemeInheritor, theme, fontsToLoad } from './components'

let root: Root
const render = (props: any) => {
  const { container, isFromKangaroo } = props
  root = createRoot(
    container
      ? container.querySelector('#kc-root')
      : document.getElementById('kc-root')
  )
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeInheritor theme={theme}>
          <CssBaseline />
          {isFromKangaroo && <App />}
        </ThemeInheritor>
      </StyledEngineProvider>
    </React.StrictMode>
  )
}

if (!(window as any).__POWERED_BY_QIANKUN__) {
  Promise.all(fontsToLoad).then(() => render({}))
}

export const bootstrap = async () => {}

export const mount = async (props: any) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}

export const update = async (props: any) => {
  console.log('update props from main framework', props)
  render(props)
}
