import { gql } from '@apollo/client'
import {
  chapterRecordFragment,
  videoRecordFragment,
  howToRecordFragment,
  toolkitRecordFragment,
  eventRecordFragment,
  thoughtLeadershipRecordFragment
} from 'gql/fragments'

export const ADVOCACY_IN_ACTION = gql`
  query AdvocacyInAction {
    advocacyInAction {
      title
      blurb
      slug
      featuredArticles {
        __typename
        ... on VideoRecord {
          ...videoRecordFragment
        }
        ... on HowToRecord {
          ...howToRecordFragment
        }
        ... on ToolkitRecord {
          ...toolkitRecordFragment
        }
        ... on EventRecord {
          ...eventRecordFragment
        }
        ... on ChapterRecord {
          ...chapterRecordFragment
        }
        ... on ThoughtLeadershipRecord {
          ...thoughtLeadershipRecordFragment
        }
      }
    }
  }
  ${chapterRecordFragment}
  ${videoRecordFragment}
  ${howToRecordFragment}
  ${toolkitRecordFragment}
  ${eventRecordFragment}
  ${thoughtLeadershipRecordFragment}
`
