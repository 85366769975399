/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prefer-destructuring */
import React, { useEffect } from 'react'
import { Typography, Grid, Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useQuery } from '@apollo/client'
import { LANDING_PAGE_QUERY } from 'gql/queries/landingPage'
import Searchbar from 'components/Searchbar'
import { useLocation } from 'react-router-dom'
import { getLinkUrl } from '../../utils/hooks'
import { Category, CmsArticle, ContentBucket } from '../../models'
import { theme } from '../../components'
import CardCarousel from '../../components/CardCarousel/CardCarousel'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import VerticalCardList from '../../components/VerticalCardList/VerticalCardList'

const StyledBox = styled(Box)<BoxProps>(() => ({
  '& .card-carousel': {
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'block'
    }
  },
  '& .vertical-card-list': {
    [theme.breakpoints.up('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'none'
    }
  }
}))

const Home = () => {
  const { data, loading } = useQuery(LANDING_PAGE_QUERY)
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/knowledge-center')
      document.title = 'Learn & Cultivate | Mathison'
    return () => {
      'Mathison'
    }
  }, [pathname])

  if (loading) return <></>

  const { landingPage } = data

  const featuredCard: CmsArticle = landingPage.featured[0]
  const contentBucket: ContentBucket = featuredCard.contentBucket
  const category: Category = featuredCard.category
  const buttonUrl: string =
    featuredCard.externalUrl ||
    getLinkUrl(featuredCard.slug, contentBucket, category)

  return (
    <Grid sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h1'>{landingPage.title}</Typography>
        <Searchbar />
      </Box>
      <Box sx={{ margin: '24px 0 25px' }}>
        <ArticleCard
          articleVariant='featured'
          url={buttonUrl}
          title={featuredCard.title}
          blurb={featuredCard.blurb}
          minutes={featuredCard.minutes}
          startDate={featuredCard.startDate}
          onDemand={featuredCard.onDemand}
          contentBucket={featuredCard.contentBucket}
          category={featuredCard.category}
          image={featuredCard.image}
          tags={featuredCard.tags}
        />
      </Box>
      <StyledBox>
        <Typography sx={{ padding: 0 }} variant='h5'>
          Newly added
        </Typography>
        <Box className='card-carousel'>
          <CardCarousel contentArray={landingPage.newlyAdded} />
        </Box>
        <Box className='vertical-card-list'>
          <VerticalCardList contentCards={landingPage.newlyAdded} />
        </Box>
      </StyledBox>
      <StyledBox>
        <Typography sx={{ padding: 0 }} variant='h5'>
          Recommended
        </Typography>
        <Box className='card-carousel'>
          <CardCarousel contentArray={landingPage.recommended} />
        </Box>
        <Box className='vertical-card-list'>
          <VerticalCardList contentCards={landingPage.recommended} />
        </Box>
      </StyledBox>
    </Grid>
  )
}

export default Home
