/* eslint-disable react/require-default-props */
import React from 'react'
import { Typography } from '@mui/material'

interface Props {
  isCategoryPage?: boolean
}

const NoSearchResultsMessage = ({ isCategoryPage }: Props) => {
  return (
    <Typography
      variant='h4'
      sx={{ marginTop: '50px', marginLeft: isCategoryPage ? '25px' : '0px' }}
    >
      We couldn&rsquo;t find anything! Try changing your search and filter
      combination to find what you&rsqou;re looking for.
    </Typography>
  )
}

export default NoSearchResultsMessage
