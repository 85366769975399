/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TranscriptType } from '../../models'
import { constructTranscriptTimeLabel } from '../../utils/hooks'
import StructuredTextContainer from '../Layout/StructuredTextContainer/StructuredTextContainer'

const TimeWrapper = styled(Grid)(() => ({
  fontFamily: 'IBM Plex Sans',
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '-0.01em',
  fontWeight: 500,
  color: '#9C28A7'
}))

const CopyWrapper = styled(Grid)(() => ({
  fontFamily: 'IBM Plex Sans',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400
}))

const Transcript = (props: TranscriptType) => {
  const timeLabel: string = constructTranscriptTimeLabel(props)
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={3}
      sx={{ marginLeft: '1.5%' }}
    >
      <TimeWrapper item sm={12} lg={1.1}>
        <p style={{ paddingTop: '3px' }}>{timeLabel}</p>
      </TimeWrapper>
      <CopyWrapper item sm={12} lg={10.9}>
        <StructuredTextContainer content={props.copy} />
      </CopyWrapper>
    </Grid>
  )
}
export default Transcript
