/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/material/Box'
import Grid, { GridProps } from '@mui/material/Grid'
import { useQuery } from '@apollo/client'
import { INSIGHT_QUERY } from 'gql/queries/contentBuckets'
import { INCLUSIVE_LEADERSHIP } from 'gql/queries/inclusiveLeadership'
import { theme } from '../../components'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import { getLinkUrl } from '../../utils/hooks'
import { CmsArticle, Category } from '../../models'

const TitleContainer = styled(Box)<BoxProps>(() => ({
  width: '50%',
  paddingBottom: '50px'
}))

const CardGridContainer = styled(Grid)<GridProps>(() => ({
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    },
    [theme.breakpoints.up('xl')]: {
      height: '400px'
    }
  }
}))

const InclusiveLeadership = () => {
  const { data, loading } = useQuery(INCLUSIVE_LEADERSHIP)
  // TODO: static value until we determine whether we need a category for this (more insights articles)
  const { data: insightData, loading: insightLoading } = useQuery(
    INSIGHT_QUERY,
    { variables: { slug: 'community-insights-report' } }
  )

  if (loading || insightLoading) return <></>

  const { inclusiveLeadership } = data

  const { title, blurb, featuredArticles } = inclusiveLeadership
  const { communityInsight } = insightData
  // TODO: static value until we determine whether we need a category for this (more insights articles)
  const insightCategory: Category = { slug: 'community-insights' }

  return (
    <Box sx={{ width: '100%' }}>
      <TitleContainer>
        <Typography variant='h1' sx={{ marginBottom: '48px' }}>
          {title}
        </Typography>
        <Typography
          variant='h6'
          sx={{
            fontSize: '1.375rem',
            lineHeight: '2rem',
            letterSpacing: '0.7px',
            fontWeight: 300
          }}
        >
          {blurb}
        </Typography>
      </TitleContainer>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <>
          {communityInsight && !insightLoading && (
            <CardGridContainer key='category-card-0' item sm={12} xl={6}>
              <ArticleCard
                articleVariant='content'
                url='/community-insights/community-insights-report'
                title={communityInsight.title}
                blurb={communityInsight.blurb}
                minutes={communityInsight.minutes}
                contentBucket={communityInsight.contentBucket}
                category={insightCategory}
                tags={communityInsight.tags}
              />
            </CardGridContainer>
          )}
          {featuredArticles && featuredArticles.length > 0 ? (
            <>
              {featuredArticles.map((article: CmsArticle, index: number) => (
                <CardGridContainer
                  key={`category-card-${index + 1}`}
                  item
                  sm={12}
                  xl={6}
                >
                  <ArticleCard
                    articleVariant='content'
                    url={
                      article.externalUrl ||
                      getLinkUrl(
                        article.slug,
                        article.contentBucket,
                        article.category
                      )
                    }
                    title={article.title}
                    blurb={article.blurb}
                    minutes={article.minutes}
                    contentBucket={article.contentBucket}
                    category={article.category}
                    tags={article.tags}
                  />
                </CardGridContainer>
              ))}
            </>
          ) : null}
        </>
      </Grid>
    </Box>
  )
}

export default InclusiveLeadership
