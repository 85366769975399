import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { CHAPTER_QUERY } from 'gql/queries/chapter'
import { useParams } from 'react-router-dom'
import ArticleContainer from '../../components/Layout/ArticleContainer/ArticleContainer'
import { getChipColorByContentBucketSlug } from '../../utils/hooks'
import { ContentLabel } from '../../models'

const Chapter = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery(CHAPTER_QUERY, { variables: { slug } })

  if (loading) return null

  const { chapter } = data

  const label =
    ContentLabel[chapter.contentBucket.slug as keyof typeof ContentLabel]
  const bgColor = getChipColorByContentBucketSlug(chapter.contentBucket.slug)

  return (
    <Box sx={{ width: '100%' }}>
      <ArticleContainer
        title={chapter.book.title}
        label={label}
        bgColor={bgColor}
        time={chapter.minutes}
        content={chapter.content}
        pdfUrl={chapter.pdf ? chapter.pdf.url : undefined}
        chapterTitle={chapter.title}
        chapterNumber={chapter.chapterNumber}
        sources={chapter.sources}
      />
    </Box>
  )
}

export default Chapter
