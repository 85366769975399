import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import ChapterNavigation from 'components/ChapterNavigation'
import ChapterSources from 'components/ChapterSources/ChapterSources'
import PageEyebrow from 'components/PageEyebrow/PageEyebrow'
import StructuredTextContainer from '../StructuredTextContainer/StructuredTextContainer'
import { ArticleContainerProps } from '../../../models'

const ArticleContainer = (props: ArticleContainerProps) => {
  const {
    title,
    label,
    bgColor,
    time,
    dateTime,
    content,
    isVideo,
    pdfUrl,
    chapterTitle,
    chapterNumber,
    sources
  } = props

  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid item>
        <PageEyebrow
          label={label}
          backgroundColor={bgColor}
          time={time}
          dateTime={dateTime}
          isVideo={isVideo}
          pdfUrl={pdfUrl}
        />
        <Typography
          variant='h1'
          sx={{
            paddingBottom: '32px',
            width: '70%'
          }}
        >
          {chapterTitle || title}
        </Typography>
        {chapterNumber ? (
          <Paper
            sx={{
              border: '1px solid #E5E5E5',
              borderRadius: '8px',
              padding: '32px 12% 48px 12%',
              width: '100%'
            }}
          >
            <Typography
              variant='subtitle1'
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '16px',
                letterSpacing: '0.17em'
              }}
            >
              CHAPTER {chapterNumber}
            </Typography>
            <StructuredTextContainer content={content} />
            <ChapterNavigation chapterNumber={chapterNumber} />
            {sources && chapterNumber !== '11' && (
              <ChapterSources sources={sources} />
            )}
          </Paper>
        ) : (
          <StructuredTextContainer content={content} />
        )}
      </Grid>
    </Grid>
  )
}
export default ArticleContainer
