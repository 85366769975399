export const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    base64
  }
`

export const metaTagsFragment = `
  fragment metaTagsFragment on Tag {
    attributes
    content
    tag
  }
`

export const categoryContentFragment = `
  title
  blurb
  slug
  minutes
  contentBucket {
    slug
  }
  category {
    slug
  }
  tags
  image {
    responsiveImage(imgixParams:{fm: jpg, fit: crop, w: 1100, h: 700}) {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
      base64
    }
  }
`

export const chapterRecordFragment = `
  fragment chapterRecordFragment on ChapterRecord {
    ${categoryContentFragment}
  }
`

export const eventRecordFragment = `
  fragment eventRecordFragment on EventRecord {
    onDemand
    externalUrl
    startDate
    ${categoryContentFragment}
  }
`

export const howToRecordFragment = `
  fragment howToRecordFragment on HowToRecord {
    ${categoryContentFragment}
  }
`

export const interviewRecordFragment = `
  fragment interviewRecordFragment on InterviewRecord {
    ${categoryContentFragment}
  }
`

export const toolkitRecordFragment = `
  fragment toolkitRecordFragment on ToolkitRecord {
    ${categoryContentFragment}
  }
`

export const videoRecordFragment = `
  fragment videoRecordFragment on VideoRecord {
    ${categoryContentFragment}
  }
`

export const thoughtLeadershipRecordFragment = `
  fragment thoughtLeadershipRecordFragment on ThoughtLeadershipRecord {
    ${categoryContentFragment}
  }
`
