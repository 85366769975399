export const FormatFilterValues = {
  'How To': 'allHowTos',
  Toolkit: 'allToolkits',
  Event: 'allEvents',
  Video: 'allVideos',
  Book: 'allChapters',
  'Thought Leadership': 'allThoughtLeaderships'
}

export const IntendedForFilterValues = [
  'DEI Leaders',
  'Executives',
  'Hiring Managers',
  'HR Leaders',
  'Talent Team',
  'Team Members',
  'Marketing Team'
]

export const CategoryFilterValues = [
  'Defining & Tracking',
  'Attracting & Sourcing',
  'Interviewing & Engaging',
  'Onboarding & Advancing'
]
