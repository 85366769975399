import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='download-icon'>
          <rect id='Rectangle' x='0' y='0' width='24' height='24'></rect>
          <g
            id='icon'
            transform='translate(4.000000, 3.000000)'
            stroke='#000000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
          >
            <path
              d='M17,13.4583333 L17,16.5734373 C16.9980733,16.6315938 16.9845867,16.6888028 16.96124,16.7416293 C16.9378933,16.7945742 16.9050267,16.8420706 16.8638867,16.8813944 C16.7709533,16.959805 16.65456,17.0017345 16.5351067,16.9997209 L0.464293614,16.9997209 C0.344534288,17.0038665 0.227472294,16.9617001 0.135581633,16.8813944 C0.0928436355,16.843492 0.0589116374,16.7962324 0.0354176388,16.7429321 C0.0119349735,16.6897503 0,16.6319492 0,16.5734373 L0,13.4583333'
              id='Path'
            ></path>
            <line
              x1='8.11041667'
              y1='0'
              x2='8.11041667'
              y2='10.625'
              id='Path'
            ></line>
            <polyline
              id='Path'
              points='12.75 8.5 8.14583333 11.3333333 3.54166667 8.5'
            ></polyline>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default DownloadIcon
