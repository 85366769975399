import React, { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)<ButtonProps>(({ variant, theme }) => ({
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: '-0.2px',
  ...(variant && {
    color:
      variant === 'outlined'
        ? theme.palette.common.black
        : theme.palette.common.white,
    backgroundColor:
      variant === 'contained'
        ? theme.palette.common.black
        : theme.palette.common.white,
    textTransform: 'none',
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: variant === 'outlined' ? 6 : 8,
    padding: variant === 'outlined' ? '12px 15px' : '8px 12px',
    lineHeight: '20px',
    '& .MuiSvgIcon-root': {
      fontSize: variant === 'outlined' ? '25px' : '5px'
    },
    '& .MuiButton-endIcon': {
      marginLeft: variant === 'outlined' ? '20px' : '0'
    },
    '& .mathison-icon': {
      display: variant === 'outlined' ? 'block' : 'none'
    },
    '&:hover': {
      backgroundColor:
        variant === 'outlined'
          ? theme.palette.common.white
          : theme.palette.common.black,
      color:
        variant === 'outlined'
          ? theme.palette.common.black
          : theme.palette.common.white,
      border: `1px solid ${
        variant === 'outlined'
          ? theme.palette.common.black
          : theme.palette.common.black
      }`,
      '& .mathison-icon': {
        stroke:
          variant === 'outlined'
            ? theme.palette.common.black
            : theme.palette.common.white
      }
    }
  })
}))

type Props = {
  variant?: 'text' | 'outlined' | 'contained' | undefined
}

const CtaButton: FC<Props & ButtonProps> = React.forwardRef(
  (props: Props, ref) => {
    return (
      <StyledButton
        ref={ref}
        variant={props.variant}
        {...props}
        disableElevation
      />
    )
  }
)
CtaButton.displayName = 'CtaButton'

export default CtaButton
