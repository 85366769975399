import axios from 'axios'

export const search = async (
  searchInput: string | undefined,
  setSearchSuggestionsOrResults: Function,
  setLoading: Function
) => {
  const result = await axios.post(
    'https://search-api.swiftype.com/api/v1/public/engines/suggest.json',
    {
      engine_key: process.env.REACT_APP_SWIFTYPE_ENGINE_KEY,
      q: searchInput
    }
  )
  const { data } = result
  const results = Array.prototype.concat.apply([], Object.values(data.records))
  setSearchSuggestionsOrResults(results)
  setLoading(false)
}
