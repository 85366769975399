/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid, GridProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components'
import { CmsArticle } from 'models'
import { getLinkUrl } from 'utils/hooks'
import ArticleCard from 'components/ArticleCard/ArticleCard'
import { useQuery } from '@apollo/client'
import { CATEGORY_CONTENT_QUERY, CATEGORY_QUERY } from 'gql/queries/categories'
import { AUDIENCES_QUERY } from 'gql/queries/audience'
import LoadingMessage from 'components/LoadingMessage'
import NoSearchResultsMessage from 'components/NoSearchResultsMessage'

import Filter from './Filter'
import { FormatFilterValues, IntendedForFilterValues } from './constants'

const CardGridContainer = styled(Grid)<GridProps>(() => ({
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    }
  }
}))

const INITIAL_CATEGORY_PAGE = {
  category: {
    blurb: '',
    id: '',
    name: '',
    slug: '',
    __typename: ''
  }
}

const CategoryPage = () => {
  const [formatFilter, setFormatFilter] = useState<string[]>([])
  const [audienceFilter, setAudienceFilter] = useState<string[]>([])
  const { categorySlug } = useParams()
  const { data = INITIAL_CATEGORY_PAGE } = useQuery(CATEGORY_QUERY, {
    variables: { slug: categorySlug },
    nextFetchPolicy: 'cache-only'
  })
  const { data: audienceData } = useQuery(AUDIENCES_QUERY, {
    nextFetchPolicy: 'cache-only'
  })

  const getAudienceIds = () => {
    const audienceIds = [] as string[]
    const filteredAudiences = audienceData?.allAudiences
      ? audienceData.allAudiences.filter((audience: any) =>
          audienceFilter.includes(audience.name)
        )
      : undefined
    filteredAudiences.forEach((audience: any) => audienceIds.push(audience.id))
    return audienceIds
  }

  const { data: contentData, loading } = useQuery(CATEGORY_CONTENT_QUERY, {
    variables: {
      categoryId: data!.category!.id,
      audienceId: audienceFilter.length ? getAudienceIds() : undefined
    }
  })
  const { category } = data

  const getCategoryContent = () => {
    if (contentData) {
      if (formatFilter.length > 0) {
        let categoryContent = [] as CmsArticle[]
        formatFilter.forEach(filter => {
          const index =
            FormatFilterValues[filter as keyof typeof FormatFilterValues]
          categoryContent = [...categoryContent, ...contentData[index]]
        })
        return categoryContent
      }
      return [
        ...contentData.allChapters,
        ...contentData.allEvents,
        ...contentData.allHowTos,
        ...contentData.allInterviews,
        ...contentData.allToolkits,
        ...contentData.allVideos,
        ...contentData.allThoughtLeaderships
      ] as CmsArticle[]
    }
    return undefined
  }

  useEffect(() => {
    setAudienceFilter([])
    setFormatFilter([])
  }, [category])

  const categoryContent = getCategoryContent()

  return (
    <Box sx={{ width: '100%' }}>
      <Grid paddingBottom='42px' container>
        <Grid item sm={12} xl={6}>
          <Typography variant='h1'>{category.name}</Typography>
        </Grid>
      </Grid>
      <Grid paddingBottom='32px' container>
        <Filter
          name='Format'
          filterValues={Object.keys(FormatFilterValues)}
          selection={formatFilter}
          setSelection={setFormatFilter}
        />
        <Filter
          name='Intended for'
          filterValues={IntendedForFilterValues}
          selection={audienceFilter}
          setSelection={setAudienceFilter}
        />
      </Grid>
      {loading ? (
        <LoadingMessage />
      ) : (
        <Grid container rowSpacing={3} columnSpacing={3}>
          {categoryContent && categoryContent.length > 0 ? (
            <>
              {categoryContent.map((content: CmsArticle, index: number) => (
                <CardGridContainer
                  key={`category-card-${index}`}
                  item
                  sm={12}
                  xl={6}
                >
                  <ArticleCard
                    articleVariant='content'
                    url={
                      content.externalUrl ||
                      getLinkUrl(
                        content.slug,
                        content.contentBucket,
                        content.category
                      )
                    }
                    title={content.title}
                    blurb={content.blurb}
                    minutes={content.minutes}
                    startDate={content.startDate}
                    onDemand={content.onDemand}
                    contentBucket={content.contentBucket}
                    category={content.category}
                    tags={content.tags}
                  />
                </CardGridContainer>
              ))}
            </>
          ) : (
            <NoSearchResultsMessage isCategoryPage />
          )}
        </Grid>
      )}
    </Box>
  )
}

export default CategoryPage
