import React from 'react'
import { Grid } from '@mui/material'
import TagChip from '../TagChip/TagChip'

interface TagsComponentProps {
  tags?: string[] | null
}

const TagsComponent = (props: TagsComponentProps) => {
  const { tags } = props
  return (
    <Grid container spacing={1}>
      {tags && tags.length > 0
        ? tags.map((tag: string, index: number) => (
            <React.Fragment key={`tag-${index}`}>
              {index < 4 ? (
                <Grid sx={{ paddingBottom: '20px' }} item>
                  <TagChip tag={tag} />
                </Grid>
              ) : null}
            </React.Fragment>
          ))
        : null}
    </Grid>
  )
}

export default TagsComponent
