/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { ContentLabel } from 'models'
import { getChipColorByContentBucketSlug } from 'utils/hooks'
import ArticleContainer from 'components/Layout/ArticleContainer/ArticleContainer'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Box from '@mui/material/Box'
import { INTERVIEW_QUERY } from '../../gql/queries/contentBuckets'

const InterviewContent = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery(INTERVIEW_QUERY, { variables: { slug } })

  if (loading) return <></>

  const { interview } = data

  const label =
    ContentLabel[interview.contentBucket.slug as keyof typeof ContentLabel]
  const bgColor = getChipColorByContentBucketSlug(interview.contentBucket.slug)

  return (
    <Box sx={{ width: '100%' }}>
      <ArticleContainer
        title={interview.title}
        label={label}
        bgColor={bgColor}
        time={interview.minutes}
        content={interview.content}
        pdfUrl={interview.pdf ? interview.pdf.url : undefined}
      />
    </Box>
  )
}

export default InterviewContent
