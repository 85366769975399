import React from 'react'
import { Author } from '../../models'
import AuthorCard from '../AuthorCard/AuthorCard'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

interface AuthorsProps {
  authors: Author[]
}

const AuthorsWrapper = styled(Box)<BoxProps>(() => ({
  paddingBottom: 24,
  maxWidth: 550
}))

const AuthorTitleContainer = styled(Box)<BoxProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 700,
  textTransform: 'uppercase',
  paddingTop: '10px',
  paddingBottom: '24px',
  letterSpacing: '0.17em',
  borderTop: '1px solid #E5E5E5'
}))

const Authors = (props: AuthorsProps) => {
  const { authors } = props
  return (
    <AuthorsWrapper>
      <AuthorTitleContainer>MEET THE AUTHORS</AuthorTitleContainer>
      {authors && authors.length > 0 ? (
        <>
          {authors.map((author: Author, index: number) => (
            <AuthorCard
              key={`author-${index}`}
              imgSrc={author.photo?.url}
              firstName={author.firstName}
              lastName={author.lastName}
              bio={author.bio}
              imageOption={(index + 1) % 2 === 0 ? 'blue' : 'purple'}
            />
          ))}
        </>
      ) : null}
    </AuthorsWrapper>
  )
}

export default Authors
