import React from 'react'
import Stack from '@mui/material/Stack'
import ContentTypeChip from '../ContentTypeChip'
import ReadViewTimeChip from '../ReadViewTimeChip/ReadViewTimeChip'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import DateTimeChip from '../DateTimeChip/DateTimeChip'

interface CardEyebrowProps {
  label: string
  backgroundColor: string
  onDemand?: boolean | null | undefined
  readTime?: number | undefined
  dateTime?: string | undefined
  bookmark?: () => void
  cardGroup?: 'two' | 'three' | undefined
  articleVariant?: 'featured' | 'content' | 'chapter' | 'searchSuggestion'
}

interface ChipWrapperProps extends BoxProps {
  articleVariant?: string
}

const ChipWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'articleVariant'
})<ChipWrapperProps>(({ articleVariant }) => ({
  marginTop: articleVariant === 'searchSuggestion' ? '0' : '3.5px'
}))

export const CardEyebrow = ({
  label,
  backgroundColor,
  onDemand,
  readTime,
  dateTime,
  cardGroup,
  articleVariant
}: CardEyebrowProps) => {
  return (
    <Stack
      direction='row'
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
    >
      <ChipWrapper articleVariant={articleVariant}>
        <ContentTypeChip label={label} backgroundColor={backgroundColor} />
        {dateTime ? (
          <DateTimeChip
            cardGroup={cardGroup}
            date={dateTime}
            onDemand={onDemand}
          />
        ) : (
          <>
            {readTime ? (
              <ReadViewTimeChip time={readTime} onPage={false} label={label} />
            ) : null}
          </>
        )}
      </ChipWrapper>
    </Stack>
  )
}

export default CardEyebrow
