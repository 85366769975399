import React from 'react'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { BlueBlobIcon, PurpleBlobIcon } from '../Icons'

interface MathisonAvatarProps extends AvatarProps {
  imageoption?: 'purple' | 'blue'
}

interface AuthorAvatarProps {
  imgSrc: string
  imageOption?: 'purple' | 'blue'
  firstName: string
  lastName: string
}

const MathisonAvatar = styled(Avatar)<MathisonAvatarProps>(() => ({
  width: 125,
  height: 125,
  filter: 'grayscale(1)'
}))

const AuthorAvatar = (props: AuthorAvatarProps) => {
  const { firstName, lastName, imgSrc, imageOption } = props
  return (
    <>
      {imageOption ? (
        <Box sx={{ height: 125, width: 150, position: 'relative' }}>
          {imageOption === 'blue' ? (
            <BlueBlobIcon
              fontSize='large'
              viewBox={'0 0 28 28'}
              sx={{
                width: 50,
                height: 50,
                position: 'absolute',
                top: 0,
                left: -10,
                zIndex: 1
              }}
            />
          ) : (
            <PurpleBlobIcon
              fontSize='large'
              viewBox={'0 0 28 28'}
              sx={{
                width: 50,
                height: 50,
                position: 'absolute',
                bottom: 10,
                right: 6,
                zIndex: 1
              }}
            />
          )}
          <MathisonAvatar
            alt={`${firstName} ${lastName}`}
            src={imgSrc}
            imageoption={imageOption}
          />
        </Box>
      ) : (
        <Box>
          <MathisonAvatar
            alt={`${firstName} ${lastName}`}
            src={imgSrc}
            imageoption={imageOption}
          />
        </Box>
      )}
    </>
  )
}

export default AuthorAvatar
