import { responsiveImageFragment } from '../fragments'
import { gql } from '@apollo/client'

export const CHAPTERS_QUERY = gql`
  query Chapters {
    allChapters {
      slug
    }
  }
`

export const CHAPTER_QUERY = gql`
  query Chapter($slug: String) {
    chapter(filter: { slug: { eq: $slug } }) {
      id
      chapterNumber
      book {
        title
      }
      contentBucket {
        slug
      }
      category {
        slug
      }
      minutes
      title
      pdf {
        url
        alt
      }
      content {
        value
        blocks {
          __typename
          ... on ImageBlockRecord {
            id
            fullSizeImage
            image {
              url
              width
              height
              responsiveImage(imgixParams: { fm: jpg }) {
                ...responsiveImageFragment
              }
            }
          }
        }
      }
      image {
        responsiveImage(imgixParams: { fm: jpg, fit: crop, w: 600, h: 600 }) {
          ...responsiveImageFragment
        }
      }
      sources {
        id
        link {
          value
        }
      }
    }
  }
  ${responsiveImageFragment}
`
