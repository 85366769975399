import React from 'react'
import { CmsArticle } from '../../models'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import ArticleCard from '../ArticleCard/ArticleCard'
import { getLinkUrl } from '../../utils/hooks'

interface VerticalCardListProps {
  contentCards: CmsArticle[]
}

const VerticalCardList = ({ contentCards }: VerticalCardListProps) => {
  return (
    <Box sx={{ padding: '16px 0 25px' }}>
      {contentCards && contentCards.length > 0 ? (
        <Grid container rowSpacing={3} flexDirection='column'>
          {contentCards.map((contentCard: CmsArticle, index: number) => (
            <Grid key={`list-card-${index}`} item xs={12}>
              <ArticleCard
                articleVariant='content'
                url={
                  contentCard.externalUrl ||
                  getLinkUrl(
                    contentCard.slug,
                    contentCard.contentBucket,
                    contentCard.category
                  )
                }
                title={contentCard.title}
                blurb={contentCard.blurb}
                minutes={contentCard.minutes}
                startDate={contentCard.startDate}
                onDemand={contentCard.onDemand}
                contentBucket={contentCard.contentBucket}
                category={contentCard.category}
                tags={contentCard.tags ?? undefined}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  )
}
export default VerticalCardList
