import React from 'react'
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import ContentTypeChip from '../ContentTypeChip'
import ReadViewTimeChip from '../ReadViewTimeChip/ReadViewTimeChip'
import DownloadButton from '../DownloadButton/DownloadButton'
import DateTimeChip from '../DateTimeChip/DateTimeChip'

interface PageEyebrowProps {
  label: string
  backgroundColor: string
  dateTime?: string
  onDemand?: boolean | null | undefined
  time?: number
  isVideo?: boolean
  pdfUrl?: string
  childPdfUrl?: string
  videoUrl?: string
  bookmark?: () => void
}

const StyledStack = styled(Stack)<StackProps>(() => ({
  paddingBottom: '20px',
  display: 'flex',
  alignItems: 'center'
}))

export const PageEyebrow = (props: PageEyebrowProps) => {
  const {
    label,
    backgroundColor,
    dateTime,
    onDemand,
    time,
    isVideo,
    pdfUrl,
    childPdfUrl,
    videoUrl
  } = props

  return (
    <StyledStack direction='row'>
      <ContentTypeChip label={label} backgroundColor={backgroundColor} />
      {dateTime ? (
        <DateTimeChip date={dateTime} onDemand={onDemand} />
      ) : (
        <ReadViewTimeChip time={time} onPage isVideo={isVideo} />
      )}
      <DownloadButton
        contentLabel={label}
        pdfUrl={pdfUrl}
        childPdfUrl={childPdfUrl}
        videoUrl={videoUrl}
      />
    </StyledStack>
  )
}

export default PageEyebrow
