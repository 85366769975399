/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { styled } from '@mui/material/styles'
import Card, { CardProps } from '@mui/material/Card'
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import CardContent, { CardContentProps } from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box, { BoxProps } from '@mui/material/Box'
import HighlightText from 'components/HighlightText'
import CardEyebrow from '../CardEyebrow/CardEyebrow'
import CmsLink from '../CmsLink/CmsLink'
import TagsComponent from '../TagsComponent/TagsComponent'
import CardButtonLink from '../CardButtonLink/CardButtonLink'
import ChapterCardEyebrow from '../ChapterCardEyebrow/ChapterCardEyebrow'
import { theme, Color } from '../SharedComponents'
import { getChipColorByContentBucketSlug } from '../../utils/hooks'
import { BaseArticle, ContentLabel } from '../../models'

const ButtonWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 'auto'
}))

interface CardContentWrapperProps extends BoxProps {
  articleVariant?: 'content' | 'featured' | 'chapter' | 'searchSuggestion'
  cardGroup: 'two' | 'three' | undefined
  isSearchResultsCard?: boolean
}

const CardContentWrapper = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'articleVariant' &&
    prop !== 'cardGroup' &&
    prop !== 'isSearchResultsCard'
})<CardContentWrapperProps>(
  ({ articleVariant, cardGroup, isSearchResultsCard }) => ({
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'IBM Plex Sans',
    [theme.breakpoints.up('xs')]: {
      padding:
        cardGroup === 'three'
          ? '32px'
          : articleVariant === 'chapter'
          ? '32px 58px 40px 40px'
          : isSearchResultsCard
          ? '48px 0 16px'
          : '32px 32px 24px 40px'
    },
    [theme.breakpoints.up('xl')]: {
      padding:
        articleVariant === 'featured'
          ? '39px 41px 72px 88px'
          : cardGroup === 'three'
          ? '36px 41px 40px 28px'
          : articleVariant === 'chapter'
          ? '32px 58px 40px 28px'
          : articleVariant === 'searchSuggestion'
          ? '8px 16px 8px 8px'
          : isSearchResultsCard
          ? '48px 0 16px'
          : '36px 41px 40px 32px'
    },
    flexGrow: 1
  })
)

const CardContentEyebrowContainer = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isSearchResultsCard' && prop !== 'articleVariant'
})<CardContentWrapperProps>(({ isSearchResultsCard, articleVariant }) => ({
  paddingBottom:
    isSearchResultsCard && articleVariant === 'searchSuggestion'
      ? '0px'
      : isSearchResultsCard
      ? '50px'
      : '40px'
}))

const StyledCardMedia = styled(CardMedia)<CardMediaProps>(() => ({
  backgroundPosition: 'top',
  [theme.breakpoints.up('xs')]: {
    height: '100%',
    minHeight: '440px'
  }
}))

interface ArticleCardContentProps extends CardContentProps {
  articleVariant: 'content' | 'featured' | 'chapter' | 'searchSuggestion'
  cardGroup: 'three' | 'two' | undefined
  isSearchResultsCard?: boolean
}

const ArticleCardContent = styled(CardContent, {
  shouldForwardProp: prop =>
    prop !== 'articleVariant' &&
    prop !== 'cardGroup' &&
    prop !== 'isSearchResultsCard'
})<ArticleCardContentProps>(
  ({ articleVariant, cardGroup, isSearchResultsCard }) => ({
    flexGrow: 1,
    '&.MuiCardContent-root': {
      padding: articleVariant === 'searchSuggestion' ? '0px' : '0 0 32px 0'
    },
    [theme.breakpoints.up('xs')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth:
        articleVariant === 'featured'
          ? '75%'
          : cardGroup === 'three'
          ? '308px'
          : articleVariant === 'chapter' || isSearchResultsCard
          ? '100%'
          : '80%'
    }
  })
)

interface ArticleCardContainerProps extends CardProps {
  isSearchResultsCard?: boolean
  articleVariant?: string
  searchInput?: string
  isOpen?: string
}

const ArticleCardContainer = styled(Card, {
  shouldForwardProp: prop =>
    prop !== 'isSearchResultsCard' && prop !== 'articleVariant'
})<ArticleCardContainerProps>(({ isSearchResultsCard, articleVariant }) => ({
  borderRadius: isSearchResultsCard ? 0 : '8px',
  border: isSearchResultsCard ? 'none' : '1px solid #E5E5E5',
  borderBottom:
    isSearchResultsCard && articleVariant === 'searchSuggestion'
      ? 'none'
      : isSearchResultsCard
      ? `1px solid ${Color.grey600}`
      : '1px solid #E5E5E5',
  padding: articleVariant === 'searchSuggestion' ? '0 0 0 16px' : '0',
  width: '100%',
  display: 'flex',
  margin: articleVariant === 'searchSuggestion' ? '8px 0px 8px 0px' : '0',
  backgroundColor:
    articleVariant === 'searchSuggestion' || isSearchResultsCard
      ? 'transparent'
      : 'default',
  '&:hover': {
    border: isSearchResultsCard ? 'none' : '1px solid #CDCDCD',
    borderBottom:
      isSearchResultsCard && articleVariant === 'searchSuggestion'
        ? 'none'
        : isSearchResultsCard
        ? `1px solid ${Color.grey600}`
        : '1px solid #E5E5E5',
    cursor: 'pointer',
    backgroundColor:
      articleVariant === 'searchSuggestion' ? Color.grey300 : 'default'
  }
}))

interface ArticleCardProps extends BaseArticle {
  articleVariant: 'content' | 'featured' | 'chapter' | 'searchSuggestion'
  cardGroup?: 'two' | 'three' | undefined
  url: string
  bookmark?: () => void
  chapterNumber?: number
  tags?: string[] | null
  isSearchResultsCard?: boolean
  categoryTitle?: string
  searchInput?: string
}

const ArticleCard = ({
  articleVariant,
  cardGroup,
  url,
  chapterNumber,
  contentBucket,
  blurb,
  title,
  tags,
  minutes,
  onDemand,
  startDate,
  image,
  isSearchResultsCard,
  categoryTitle,
  searchInput
}: ArticleCardProps) => {
  const label = ContentLabel[contentBucket.slug as keyof typeof ContentLabel]
  const chipColor: string = getChipColorByContentBucketSlug(contentBucket.slug)
  const chapterLabel: string = `Read chapter ${chapterNumber}`

  let category
  switch (categoryTitle) {
    case 'defining-tracking-dei':
      category = 'Defining & Tracking'
      break
    case 'sourcing-attracting':
      category = 'Attracting & Sourcing'
      break
    case 'interviewing-engaging':
      category = 'Interviewing & Engaging'
      break
    case 'onboarding-advancing':
      category = 'Onboarding & Advancing'
      break
    case 'community-insights':
      category = 'Community Insight'
      break
    case 'thought-leadership':
      category = 'Thought Leadership'
      break
    default:
      category = categoryTitle
  }

  return (
    <ArticleCardContainer
      className='article-card'
      variant='outlined'
      isSearchResultsCard={isSearchResultsCard}
      articleVariant={articleVariant}
    >
      <CardActions sx={{ alignItems: 'flex-start', padding: 0, width: '100%' }}>
        <CmsLink
          url={contentBucket.slug === 'event' ? url : `/knowledge-center${url}`}
        >
          <Grid container flexGrow={1}>
            {articleVariant === 'featured' && image ? (
              <Grid item xs={12} xl={6}>
                <StyledCardMedia
                  // @ts-ignore
                  image={image.responsiveImage.src}
                />
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              xl={articleVariant === 'featured' ? 6 : 12}
              display='flex'
            >
              <CardContentWrapper
                articleVariant={articleVariant}
                cardGroup={cardGroup}
                isSearchResultsCard={isSearchResultsCard}
              >
                {!isSearchResultsCard &&
                  (chapterNumber ? (
                    <ChapterCardEyebrow chapterNumber={chapterNumber} />
                  ) : (
                    <CardContentEyebrowContainer
                      cardGroup={cardGroup}
                      articleVariant={articleVariant}
                    >
                      <CardEyebrow
                        label={label}
                        backgroundColor={chipColor}
                        readTime={minutes}
                        dateTime={startDate}
                        onDemand={onDemand}
                        cardGroup={cardGroup}
                        articleVariant={articleVariant}
                      />
                    </CardContentEyebrowContainer>
                  ))}
                <ArticleCardContent
                  articleVariant={articleVariant}
                  cardGroup={cardGroup}
                  isSearchResultsCard={isSearchResultsCard}
                >
                  {isSearchResultsCard &&
                    articleVariant !== 'searchSuggestion' && (
                      <Typography
                        variant='h7'
                        component='div'
                        sx={{
                          marginBottom: '16px',
                          fontSize: '14px',
                          lineHeight: '16px',
                          fontWeight: 700,
                          letterSpacing: '0.14em',
                          textTransform: 'uppercase',
                          color: Color.black
                        }}
                      >
                        {category}
                      </Typography>
                    )}
                  {searchInput ? (
                    <HighlightText
                      text={title}
                      searchInput={searchInput}
                      type='title'
                    />
                  ) : (
                    <Typography
                      variant='h5'
                      component='div'
                      sx={{
                        marginBottom: '16px',
                        fontSize:
                          articleVariant === 'featured' ? '24px' : '20px',
                        lineHeight: '28px',
                        fontWeight: 700,
                        letterSpacing: '-0.5px'
                      }}
                    >
                      {title}
                    </Typography>
                  )}
                  {articleVariant &&
                    (articleVariant === 'searchSuggestion' ? (
                      <HighlightText
                        text={blurb}
                        searchInput={searchInput}
                        type='blurb'
                      />
                    ) : articleVariant === 'featured' ? (
                      <Typography
                        variant='body1'
                        color='text.primary'
                        sx={{
                          fontWeight: 300,
                          fontSize:
                            articleVariant === 'featured' ? '20px' : '14px',
                          lineHeight: '28px'
                        }}
                      >
                        {blurb}
                      </Typography>
                    ) : (
                      <Typography
                        variant='body1'
                        color='text.primary'
                        sx={{
                          fontWeight: cardGroup === 'three' ? 400 : 300,
                          fontSize:
                            cardGroup === 'three' ||
                            articleVariant === 'chapter'
                              ? '16px'
                              : '18px',
                          lineHeight: '24px'
                        }}
                      >
                        {blurb}
                      </Typography>
                    ))}
                </ArticleCardContent>
                {isSearchResultsCard &&
                  articleVariant !== 'searchSuggestion' && (
                    <CardContentEyebrowContainer
                      cardGroup={cardGroup}
                      isSearchResultsCard={isSearchResultsCard}
                      articleVariant={articleVariant}
                    >
                      <CardEyebrow
                        label={label}
                        backgroundColor={chipColor}
                        readTime={minutes}
                        dateTime={startDate}
                        onDemand={onDemand}
                        cardGroup={cardGroup}
                        articleVariant={articleVariant}
                      />
                      <TagsComponent tags={tags} />
                    </CardContentEyebrowContainer>
                  )}
                {articleVariant !== 'searchSuggestion' && (
                  <ButtonWrapper>
                    <TagsComponent tags={tags} />
                    <Box sx={{ width: '100%' }}>
                      <CardButtonLink>
                        {chapterNumber
                          ? chapterLabel
                          : contentBucket.slug === 'book'
                          ? 'Read the chapter'
                          : contentBucket.slug === 'community-insight' ||
                            contentBucket.slug === 'thought-leadership'
                          ? 'Open to download'
                          : contentBucket.slug === 'video'
                          ? 'Watch video'
                          : onDemand || title.includes('ON DEMAND')
                          ? 'Watch Now'
                          : // @ts-ignore
                          Date.now() < Date.parse(startDate) ||
                            contentBucket.slug === 'event'
                          ? 'Register'
                          : 'Read more'}
                      </CardButtonLink>
                    </Box>
                  </ButtonWrapper>
                )}
              </CardContentWrapper>
            </Grid>
          </Grid>
        </CmsLink>
      </CardActions>
    </ArticleCardContainer>
  )
}
export default ArticleCard
