import React from 'react'
import { Box, Divider, BoxProps, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils/dist/types/types'
import { StructuredText } from 'react-datocms'

export const findCalloutColors: any = {
  yellow: {
    background: 'linear-gradient(180deg, #FFFAE1 0%, #FFFFFF 100%)',
    borderColor: '#FE6868'
  },
  blue: {
    background: 'linear-gradient(180deg, #EAFCFF 0%, #FFFFFF 100%)',
    borderColor: '#4CD6F1'
  },
  purple: {
    background: 'linear-gradient(180deg, #F0E8FF 0%, #FFFFFF 77.08%)',
    borderColor: '#9C28A7'
  }
}

interface CalloutProps extends BoxProps {
  color: string
  borderDirection?: string
}

export const CalloutContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'color'
})<CalloutProps>(({ color }) => ({
  background: findCalloutColors[color].background,
  width: '100%',
  padding: '56px 0 40px'
}))

export const CalloutContent = styled(Box, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'borderDirection'
})<CalloutProps>(({ color, borderDirection }) => ({
  borderLeft:
    borderDirection === 'side'
      ? `3px solid ${findCalloutColors[color].borderColor}`
      : 'none',
  margin: '0 12.5%',
  paddingLeft: borderDirection === 'side' ? '8px' : '0px'
}))

interface Props {
  color: string
  borderDirection: string
  isList: boolean
  content?: StructuredTextType
  title?: string
  listItems?: any
}

const CalloutComponent = ({
  color,
  borderDirection,
  isList,
  content,
  title,
  listItems
}: Props) => {
  return (
    <CalloutContainer color={color}>
      <CalloutContent color={color} borderDirection={borderDirection}>
        {borderDirection !== 'side' && (
          <Divider
            sx={{
              width: '140px',
              borderTop: `3px solid ${findCalloutColors[color].borderColor}`,
              borderBottom: 'none',
              marginBottom: '16px'
            }}
          />
        )}
        {isList ? (
          <Box
            sx={{
              '& p': {
                margin: '0 0 16px 0'
              }
            }}
          >
            <Typography
              variant='h6'
              sx={{
                fontSize: '16px',
                marginBottom: '16px',
                lineHeight: '24px',
                fontFamily: 'IBM Plex Sans'
              }}
            >
              {title}
            </Typography>
            <Grid container columnSpacing={3}>
              {listItems.map((item: any) => (
                <Grid item xs={6} key={item}>
                  <StructuredText data={item.content} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box className='structured-text-container'>
            <StructuredText data={content} />
          </Box>
        )}
      </CalloutContent>
    </CalloutContainer>
  )
}

export default CalloutComponent
