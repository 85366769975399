import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const PurpleBlobIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon className='blob purple-blob' {...props}>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity='0.4'
      >
        <g id='Arthur_Vector' fill='#D9178B'>
          <path
            d='M7.1350307,25.9891603 C3.26077478,24.6836584 0.689833698,20.7566269 0.0745804851,16.7285761 C-0.475975096,13.1241629 2.13187383,10.1792401 4.269783,7.23053094 C6.45184241,4.22091783 8.4344071,0.630409023 12.1265652,0.105942282 C16.1871585,-0.470858565 20.4191483,1.34802426 22.9493074,4.57713126 C25.3635356,7.65821803 25.4158727,11.8957631 24.2910651,15.6303756 C23.308357,18.8935456 20.440931,20.8424421 17.5192675,22.6065508 C14.2439343,24.5841011 10.772001,27.2145485 7.1350307,25.9891603 Z'
            id='Path'
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default PurpleBlobIcon
