/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/material/Box'
import Grid, { GridProps } from '@mui/material/Grid'
import { useQuery } from '@apollo/client'
import { ADVOCACY_IN_ACTION } from 'gql/queries/advocacyInAction'
import { theme } from '../../components'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import { getLinkUrl } from '../../utils/hooks'
import { CmsArticle } from '../../models'

const TitleContainer = styled(Box)<BoxProps>(() => ({
  width: '50%',
  paddingBottom: '50px'
}))

const CardGridContainer = styled(Grid)<GridProps>(() => ({
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    },
    [theme.breakpoints.up('xl')]: {
      height: '400px'
    }
  }
}))

const AdvocacyInAction = () => {
  const { data, loading } = useQuery(ADVOCACY_IN_ACTION)

  if (loading) return <></>

  const { advocacyInAction } = data

  const { title, blurb, featuredArticles } = advocacyInAction

  return (
    <Box sx={{ width: '100%' }}>
      <TitleContainer>
        <Typography variant='h1' sx={{ marginBottom: '48px' }}>
          {title}
        </Typography>
        <Typography
          variant='h6'
          sx={{
            fontSize: '1.375rem',
            lineHeight: '2rem',
            letterSpacing: '0.7px',
            fontWeight: 300
          }}
        >
          {blurb}
        </Typography>
      </TitleContainer>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {featuredArticles && featuredArticles.length > 0 ? (
          <>
            {featuredArticles.map((article: CmsArticle, index: number) => (
              <CardGridContainer
                key={`category-card-${index + 1}`}
                item
                sm={12}
                xl={6}
              >
                <ArticleCard
                  articleVariant='content'
                  url={
                    article.externalUrl ||
                    getLinkUrl(
                      article.slug,
                      article.contentBucket,
                      article.category
                    )
                  }
                  title={article.title}
                  blurb={article.blurb}
                  minutes={article.minutes}
                  contentBucket={article.contentBucket}
                  category={article.category}
                  tags={article.tags}
                />
              </CardGridContainer>
            ))}
          </>
        ) : null}
      </Grid>
    </Box>
  )
}

export default AdvocacyInAction
