import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const DownwardTriangleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width='11' height='5' viewBox='0 0 11 5' fill='none' {...props}>
      <path d='M0.457031 0L5.45703 5L10.457 0L0.457031 0Z' fill='black' />
    </SvgIcon>
  )
}

export default DownwardTriangleIcon
