import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Grid } from '@mui/material'
import Home from 'pages/Home'
import InclusiveLeadership from 'pages/InclusiveLeadership'
import CategoryPage from 'pages/Category'
import CommunityInsightsPage from 'pages/CommunityInsights'
import HiringForDiversity from 'pages/HiringForDiversity'
import Chapter from 'pages/HiringForDiversity/Chapter'
import HowToContent from 'pages/Category/HowTo'
import InterviewContent from 'pages/Category/Interview'
import VideoContent from 'pages/Category/VideoContent'
import SlideContent from 'pages/Category/Toolkit'
import SearchResultsPage from 'pages/SearchResults'
import InsightContent from 'pages/CommunityInsights/Insight'
import AdvocacyInAction from 'pages/AdvocacyInAction'
import ThoughtLeadership from 'pages/Category/ThoughtLeadership'
import ArticlePreview from 'pages/ArticlePreview'

const RouteProvider = () => {
  return (
    <Grid
      sx={{
        width: '90%',
        margin: '40px auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid
        sx={{
          width: '100%',
          maxWidth: '1460px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Routes>
          <Route path='/knowledge-center' element={<Home />} />
          <Route
            path='/knowledge-center/advocacy-in-action'
            element={<AdvocacyInAction />}
          />
          <Route
            path='/knowledge-center/growing-dei-leaders'
            element={<InclusiveLeadership />}
          />
          <Route
            path='/knowledge-center/inclusive-leadership'
            element={<InclusiveLeadership />}
          />
          <Route
            path='/knowledge-center/article-preview'
            element={<ArticlePreview />}
          />
          <Route
            path='/knowledge-center/hiring-for-diversity/chapter/:slug'
            element={<Chapter />}
          />
          <Route
            path='/knowledge-center/hiring-for-diversity'
            element={<HiringForDiversity />}
          />
          <Route
            path='/knowledge-center/thought-leadership/:slug'
            element={<ThoughtLeadership />}
          />
          <Route
            path='/knowledge-center/video/:slug'
            element={<VideoContent />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug'
            element={<CategoryPage />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug/video/:slug'
            element={<VideoContent />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug/thought-leadership/:slug'
            element={<ThoughtLeadership />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug/how-to/:slug'
            element={<HowToContent />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug/interview/:slug'
            element={<InterviewContent />}
          />
          <Route
            path='/knowledge-center/category/:categorySlug/toolkit/:slug/slide/:slideSlug'
            element={<SlideContent />}
          />
          <Route
            path='/knowledge-center/community-insights/:slug'
            element={<InsightContent />}
          />
          <Route
            path='/knowledge-center/community-insights'
            element={<CommunityInsightsPage />}
          />
          <Route
            path='/knowledge-center/search/:searchInput'
            element={<SearchResultsPage />}
          />
        </Routes>
      </Grid>
    </Grid>
  )
}

export default RouteProvider
