/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
// @ts-nocheck

import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils/dist/types/types'
import { StructuredText } from 'react-datocms'
import CalloutComponent from 'components/CalloutComponent'
import CoverImage from '../../CoverImage'
import ListComponent from '../../ListComponent/ListComponent'
import { ChapterContentType } from '../../../models'

interface ContentProps extends BoxProps {
  isSlide?: boolean
  isInsight?: boolean
  roundedImage?: boolean
}

const Content = styled(Box, {
  shouldForwardProp: prop => prop !== 'isSlide'
})<ContentProps>(({ isSlide, isInsight }) => ({
  width: '100%',
  h2: {
    paddingTop: isInsight ? '20px' : '40px',
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-0.02em'
  },
  '.cover-image': {
    margin: '40px 0'
  },
  ul: {
    paddingInlineStart: '30px'
  },
  li: {
    '&::marker': {
      fontWeight: 700
    },
    '& p': {
      margin: 0
    }
  },
  '& blockquote': {
    margin: 0
  },
  '& strong:last-of-type': {
    fontFamily: 'IBM Plex Sans',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px'
  },

  ...(isSlide && {
    ul: {
      paddingInlineStart: '0'
    },
    h2: {
      padding: '20px 0 0 0',
      fontSize: '16px',
      letterSpacing: '2px',
      textTransform: 'uppercase'
    },
    h3: {
      padding: '20px 0 0 0',
      fontSize: '18px',
      letterSpacing: '-0.2px',
      lineHeight: '26px',
      textTransform: 'capitalize'
    },
    h4: {
      padding: 0,
      fontSize: '18px',
      letterSpacing: '-0.2px',
      lineHeight: '26px',
      textTransform: 'capitalize'
    },
    '& p': {
      margin: '0 12% 20px 12%',
      fontFamily: 'IBM Plex Sans',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px'
    },
    '& blockquote': {
      margin: '0 10% 40px 10%',
      '& p': {
        margin: '0 2.5% 32px 2.5%',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '24px'
      }
    },
    '& .structured-text-container': {
      '& p': {
        margin: '0 0 8px 0',
        lineHeight: '24px',
        '& strong': {
          lineHeight: '16px',
          fontSize: '16px'
        }
      }
    },
    '& .structured-text-container': {
      '& p': {
        margin: '0 0 8px 0',
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'IBM Plex Sans',
        '& strong': {
          lineHeight: '16px',
          fontSize: '16px',
          fontFamily: 'IBM Plex Sans'
        }
      }
    },
    '& .MuiListItem-root': {
      padding: '16px 0 0 0',
      '& p': {
        margin: '0'
      }
    }
  })
}))

export default function StructuredTextContainer({
  content,
  roundedImage,
  isSlide,
  isInsight
}: {
  content: StructuredTextType | ChapterContentType
  isSlide?: boolean
  roundedImage?: boolean
  isInsight?: boolean
}) {
  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Content
          isSlide={isSlide}
          isInsight={isInsight}
          className='structured-text-container'
        >
          <StructuredText
            data={content}
            renderBlock={({ record }) => {
              if (record.__typename === 'ImageBlockRecord') {
                // @ts-ignore
                if (record.image.responsiveImage) {
                  // @ts-ignore
                  return (
                    <CoverImage
                      isSlide={isSlide}
                      rounded={roundedImage}
                      fullSizeImage={record.fullSizeImage}
                      responsiveImage={record.image.responsiveImage}
                      title={record.image.alt}
                    />
                  )
                }
                if (record.image) {
                  // @ts-ignore
                  return (
                    <CoverImage
                      isSlide={isSlide}
                      rounded={roundedImage}
                      fullSizeImage={record.fullSizeImage}
                      url={record.image.url}
                      width={record.image.width}
                      height={record.image.height}
                      title={record.image.alt}
                    />
                  )
                }
              }
              if (record.__typename === 'FactoidRecord') {
                // TODO: Create factoid component
                return <div>factoid component will go here</div>
              }
              if (record.__typename === 'ListBlockRecord') {
                return (
                  <ListComponent
                    isSlide={isSlide}
                    title={record.title}
                    subtitle={record.subtitle}
                    checklist={record.checklist}
                    item={record.item}
                  />
                )
              }
              if (record.__typename === 'CalloutComponentRecord') {
                return (
                  <CalloutComponent
                    color={record.backgroundColor.color}
                    borderDirection={record.backgroundColor.border}
                    content={record.content}
                    isList={false}
                  />
                )
              }

              if (record.__typename === 'CalloutListComponentRecord') {
                return (
                  <CalloutComponent
                    color={record.backgroundColor.color}
                    borderDirection={record.backgroundColor.border}
                    title={record.title}
                    listItems={record.listItem}
                    isList
                  />
                )
              }

              return <pre>{JSON.stringify(record, null, 2)}</pre>
            }}
          />
        </Content>
      </Box>
    </div>
  )
}
