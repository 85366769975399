/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Image as CmsImage, ResponsiveImageType } from 'react-datocms'
import { Link } from 'react-router-dom'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { theme } from '../SharedComponents'

interface CoverImageProps {
  title?: string | null
  url?: string | undefined
  responsiveImage?: ResponsiveImageType
  fullSizeImage?: boolean
  slug?: string | undefined
  isSlide?: boolean
  rounded?: boolean
  width?: number
  height?: number
  heroTitle?: string | undefined
  heroSubtitle?: string | undefined
  onClick?: () => void | undefined
}

interface ImageWrapperProps extends BoxProps {
  isSlide?: boolean
  fullSizeImage?: boolean
  width?: number
  height?: number
}

const ImageWrapper = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isSlide' &&
    prop !== 'width' &&
    prop !== 'height' &&
    prop !== 'fullSizeImage'
})<ImageWrapperProps>(({ isSlide, fullSizeImage, width, height }) => ({
  display: 'flex',
  justifyContent: 'center',
  ...(isSlide && {
    margin: '0 12.5% 40px 12.5%'
  }),
  ...(fullSizeImage && {
    margin: '0 0 40px 0'
  }),
  ...(width &&
    width < 1460 &&
    fullSizeImage &&
    height && {
      width,
      height,
      margin: 'auto'
    })
}))

type CmsImagePropTypes = {
  data?: ResponsiveImageType
  className?: string
  pictureClassName?: string
  fadeInDuration?: number
  intersectionThreshold?: number
  intersectionMargin?: string
  lazyLoad?: boolean
  style?: React.CSSProperties
  pictureStyle?: React.CSSProperties
  explicitWidth?: boolean
}

interface StyledCmsImageProps extends CmsImagePropTypes {
  fullSizeImage?: boolean
  rounded?: boolean
}

const StyledCmsImage = styled(CmsImage, {
  shouldForwardProp: prop => prop !== 'fullSizeImage'
})<StyledCmsImageProps>(({ fullSizeImage, rounded }) => ({
  borderRadius: '8px',
  ...(fullSizeImage && {
    borderRadius: rounded ? '8px' : 'none'
  })
}))

const isSvg = (url: string | undefined): boolean => {
  if (!url) {
    return false
  }
  const extension = url.split('.').pop()
  return extension === 'svg'
}

export default function CoverImage(props: CoverImageProps) {
  const {
    title,
    url,
    responsiveImage,
    slug,
    rounded,
    isSlide,
    fullSizeImage,
    width,
    height,
    heroTitle,
    heroSubtitle,
    onClick
  } = props

  const isSvgImage: boolean = isSvg(url)

  const image = (
    <Box onClick={onClick}>
      {isSvgImage ? (
        // eslint-disable-next-line
        <img src={url} alt={'test'} width='710px' />
      ) : (
        <ImageWrapper
          className='image-wrapper'
          isSlide={isSlide}
          width={responsiveImage?.width ?? width}
          height={responsiveImage?.height ?? height}
          fullSizeImage={fullSizeImage}
        >
          {responsiveImage && (
            <StyledCmsImage
              fullSizeImage={fullSizeImage}
              rounded={rounded}
              data={{
                ...responsiveImage,
                alt: heroTitle || heroSubtitle || title
              }}
              className='customCard'
            />
          )}
        </ImageWrapper>
      )}
    </Box>
  )
  return (
    <div className='-mx-5 sm:mx-0 cover-image'>
      {slug ? (
        <Link to={slug}>
          <a aria-label={title !== null ? title : undefined}>{image}</a>
        </Link>
      ) : (
        image
      )}
    </div>
  )
}
