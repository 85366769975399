import React from 'react'
import { ArrowLeftIcon } from 'components/SharedComponents'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: '#F3F3F3',
  padding: '21px 18px',
  '&:hover': {
    backgroundColor: '#F3F3F3'
  }
}))

const LeftArrowButton = ({ ...props }: IconButtonProps) => {
  return (
    <StyledIconButton onClick={props.onClick} disabled={props.disabled}>
      <ArrowLeftIcon color={props.disabled ? 'primary' : 'secondary'} />
    </StyledIconButton>
  )
}
export default LeftArrowButton
