/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, BoxProps, Grid, Typography } from '@mui/material'
import { VIDEO_QUERY } from 'gql/queries/contentBuckets'
import { ContentLabel, TranscriptType } from 'models'
import { getChipColorByContentBucketSlug } from 'utils/hooks'
import PageEyebrow from 'components/PageEyebrow/PageEyebrow'
import { styled } from '@mui/material/styles'
import Transcript from 'components/Transcript/Transcript'
import VideoModal from 'components/VideoModal/VideoModal'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { StructuredTextContainer } from 'components/Layout'

const StyledContentContainer = styled(Box)<BoxProps>(() => ({
  paddingLeft: '8.5%',
  marginTop: '56px'
}))

const StyledMainContentContainer = styled(Box)<BoxProps>(() => ({
  paddingLeft: '8.5%',
  marginTop: '56px',
  fontSize: '20px'
}))

const VideoContent = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery(VIDEO_QUERY, { variables: { slug } })

  if (loading) return <></>

  const { video } = data

  const label =
    ContentLabel[video.contentBucket.slug as keyof typeof ContentLabel]
  const bgColor = getChipColorByContentBucketSlug(video.contentBucket.slug)
  const videoUrl: string | null = video.video
    ? video.video.video.mp4Url
    : video.externalVideo
    ? video.externalVideo.url
    : null
  const subtitle: string =
    video.content.value.document.children[0].children[0].value

  return (
    <Box sx={{ width: '100%' }} component='article'>
      <PageEyebrow
        label={label}
        backgroundColor={bgColor}
        time={video.minutes}
        isVideo
        videoUrl={
          video.video && video.video.video
            ? video.video.video.mp4Url
            : undefined
        }
      />
      <Typography
        variant='h1'
        sx={{
          paddingBottom: '35px'
        }}
      >
        {video.title}
      </Typography>
      {videoUrl ? (
        <VideoModal
          videoUrl={videoUrl}
          image={video.image.responsiveImage}
          heroTitle={video.heroTitle}
          heroSubtitle={video.heroSubtitle}
        />
      ) : null}
      <Grid container>
        <Grid sx={{ maxWidth: '800px' }} item xs={12}>
          <StyledContentContainer>
            <Typography
              variant='body1'
              sx={{
                fontWeight: 300,
                fontSize: '18px',
                lineHeight: '24px'
              }}
            >
              {video.blurb}
            </Typography>
          </StyledContentContainer>
          <StyledMainContentContainer>
            <Typography variant='h5' sx={{ marginBottom: '24px' }}>
              Video Summary
            </Typography>
            <StructuredTextContainer
              roundedImage
              isInsight
              content={video.content}
            />
          </StyledMainContentContainer>
          <Box>
            {video.transcript && video.transcript.length > 0 ? (
              <>
                <Typography
                  sx={{ paddingLeft: '8.5%', margin: '56px 0 16px' }}
                  variant='h5'
                >
                  Transcript
                </Typography>
                {video.transcript.map(
                  (transcript: TranscriptType, index: number) => (
                    <Transcript
                      key={`transcript-${index}`}
                      minuteMark={transcript.minuteMark}
                      secondsMark={transcript.secondsMark}
                      copy={transcript.copy}
                    />
                  )
                )}
              </>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VideoContent
