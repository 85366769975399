import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ContentLabel, ToolkitSlide } from 'models'
import { getChipColorByContentBucketSlug } from 'utils/hooks'
import SlideContainer from 'components/Layout/SlideContainer/SlideContainer'
import Box from '@mui/material/Box'
import { TOOLKIT_QUERY } from '../../gql/queries/contentBuckets'

const SlideContent = () => {
  const { categorySlug, slug, slideSlug } = useParams()
  const { data, loading } = useQuery(TOOLKIT_QUERY, { variables: { slug } })

  if (loading) return null

  const { toolkit } = data
  const slide = toolkit.slides.filter(
    (current: ToolkitSlide) => current.slug === slideSlug
  )[0]

  const label =
    ContentLabel[toolkit.contentBucket.slug as keyof typeof ContentLabel]
  const bgColor = getChipColorByContentBucketSlug(toolkit.contentBucket.slug)

  return (
    <Box sx={{ width: '100%' }}>
      <SlideContainer
        title={toolkit.title}
        time={toolkit.minutes}
        label={label}
        bgColor={bgColor}
        pdfUrl={toolkit.pdf ? toolkit.pdf.url : undefined}
        slides={toolkit.slides}
        currentSlide={slide}
        baseToolkitUrl={`/category/${categorySlug}/toolkit/${slug}`}
        categoryPageUrl={`/category/${categorySlug}`}
      />
    </Box>
  )
}

export default SlideContent
