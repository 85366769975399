import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import RouteProvider from './routes'
import createApolloClient from './utils/createApolloClient'

const App = () => {
  return (
    <ApolloProvider client={createApolloClient()}>
      <Router>
        <RouteProvider />
      </Router>
    </ApolloProvider>
  )
}

export default App
