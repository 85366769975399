import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import AuthorAvatar from '../AuthorAvatar/AuthorAvatar'

interface AuthorCardProps {
  imageOption?: 'purple' | 'blue'
  imgSrc: string
  firstName: string
  lastName: string
  bio: string
}

const AuthorCard = (props: AuthorCardProps) => {
  const { imageOption, imgSrc, firstName, lastName, bio } = props
  return (
    <Stack sx={{ paddingBottom: '24px' }} direction='row' spacing={1}>
      <Box>
        <AuthorAvatar
          imgSrc={imgSrc}
          imageOption={imageOption}
          firstName={firstName}
          lastName={lastName}
        />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        sx={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
      >
        <Typography
          variant='body1'
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 700
          }}
        >
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400
          }}
        >
          {bio}
        </Typography>
      </Box>
    </Stack>
  )
}

export default AuthorCard
