import React from 'react'
import 'keen-slider/keen-slider.min.css'
import KeenSlider, { useKeenSlider } from 'keen-slider/react'
import { CmsArticle } from '../../models'
import { getLinkUrl } from '../../utils/hooks'
import { Box, BoxProps, Grid, GridProps, GridSize } from '@mui/material'
import { styled } from '@mui/material/styles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import ArticleCard from '../ArticleCard/ArticleCard'
import { theme } from '../SharedComponents'

interface CardCarouselProps {
  contentArray: CmsArticle[]
}

type CarouselType = {
  contentCards: CmsArticle[][]
  showButtons: boolean
}

const NavigationWrapper = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  margin: '20px 0 10px 0'
}))

const SliderWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row'
}))

interface ButtonWrapperProps extends BoxProps {
  showButtons: boolean
}

const ButtonWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'showButtons'
})<ButtonWrapperProps>(({ showButtons }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'right',
  padding: showButtons ? '30px 0 16px 20px' : '15px 0 0 0'
}))

const ArrowButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: '#050B0E',
  color: '#FFFFFF',
  margin: '0 5px',
  '&:disabled': {
    backgroundColor: '#EAEAEA',
    color: '#747779'
  },
  '&:hover': {
    backgroundColor: '#050B0E',
    color: '#FFFFFF'
  }
}))

const StyledGridItem = styled(Grid)<GridProps>(() => ({
  '&.spacing-4': {
    '& .content-container': {
      p: {
        height: '110px',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis ellipsis',
        whiteSpace: 'break-spaces'
      }
    }
  },
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    }
  }
}))

const createContentCards = (contentArray: CmsArticle[]): CarouselType => {
  const carouselType: CarouselType = {
    contentCards: [],
    showButtons: true
  }

  if (contentArray.length % 3 === 0 || contentArray.length === 5) {
    const slide1Array: CmsArticle[] = []
    const slide2Array: CmsArticle[] = []
    contentArray.map((content: CmsArticle, index: number) => {
      index < 3 ? slide1Array.push(content) : slide2Array.push(content)
    })
    // Content Cards
    carouselType.contentCards = [slide1Array, slide2Array]
    // Calculate whether next/previous buttons should show
    contentArray.length < 4
      ? (carouselType.showButtons = false)
      : (carouselType.showButtons = true)

    return carouselType
  } else {
    const slide1Array: CmsArticle[] = []
    const slide2Array: CmsArticle[] = []
    contentArray.map((content: CmsArticle, index: number) => {
      index < 2 ? slide1Array.push(content) : slide2Array.push(content)
    })
    // Content Cards
    carouselType.contentCards = [slide1Array, slide2Array]
    // Calculate whether next/previous buttons should show
    contentArray.length < 3
      ? (carouselType.showButtons = false)
      : (carouselType.showButtons = true)
  }
  return carouselType
}

const calculateCarouselCardGridValue = (
  carouselData: CmsArticle[][]
): GridSize => {
  if (
    carouselData &&
    carouselData.length > 0 &&
    carouselData[0] &&
    carouselData[0].length > 0
  ) {
    return carouselData[0].length % 2 === 0 ? 6 : 4
  }
  return 12
}

const CardCarousel = ({ contentArray }: CardCarouselProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s: KeenSlider) {
      setCurrentSlide(s.details().relativeSlide)
    }
  })

  const carouselType: CarouselType = createContentCards(contentArray)
  const contentCards: CmsArticle[][] = carouselType.contentCards
  const showButtons: boolean = carouselType.showButtons
  const spacingValue: GridSize = calculateCarouselCardGridValue(contentCards)

  return (
    <Box>
      <NavigationWrapper className='navigation-wrapper'>
        <SliderWrapper ref={sliderRef} className='keen-slider'>
          {contentCards &&
          contentCards.length > 0 &&
          contentCards[0] &&
          contentCards[0].length > 0 ? (
            <>
              {contentCards.map((contentCard: CmsArticle[], index: number) => (
                <Box
                  key={`carousel-slide-${index + 1}`}
                  className={`keen-slider__slide number-slide${index + 1}`}
                >
                  {contentCard && contentCard.length > 0 ? (
                    <Grid container columnSpacing={3}>
                      {contentCard.map(
                        (categoryContent: CmsArticle, index: number) => (
                          <StyledGridItem
                            className={`spacing-${spacingValue}`}
                            key={`carousel-card-${index + 1}`}
                            item
                            xs={spacingValue}
                          >
                            <ArticleCard
                              articleVariant='content'
                              cardGroup={spacingValue === 4 ? 'three' : 'two'}
                              url={
                                categoryContent.externalUrl ||
                                getLinkUrl(
                                  categoryContent.slug,
                                  categoryContent.contentBucket,
                                  categoryContent.category
                                )
                              }
                              title={categoryContent.title}
                              blurb={categoryContent.blurb}
                              minutes={categoryContent.minutes}
                              startDate={categoryContent.startDate}
                              onDemand={categoryContent.onDemand}
                              contentBucket={categoryContent.contentBucket}
                              category={categoryContent.category}
                              tags={categoryContent.tags ?? undefined}
                            />
                          </StyledGridItem>
                        )
                      )}
                    </Grid>
                  ) : null}
                </Box>
              ))}
            </>
          ) : null}
        </SliderWrapper>
        {slider && (
          <ButtonWrapper showButtons={showButtons}>
            {showButtons ? (
              <>
                <ArrowButton
                  aria-label='delete'
                  onClick={(e: any) => e.stopPropagation() || slider.prev()}
                  disabled={currentSlide === 0}
                >
                  <KeyboardArrowLeft />
                </ArrowButton>
                <ArrowButton
                  aria-label='delete'
                  onClick={(e: any) => e.stopPropagation() || slider.next()}
                  disabled={currentSlide === slider.details().size - 1}
                >
                  <KeyboardArrowRight />
                </ArrowButton>
              </>
            ) : null}
          </ButtonWrapper>
        )}
      </NavigationWrapper>
    </Box>
  )
}
export default CardCarousel
