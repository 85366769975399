/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Box, Grid, GridProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components'
import { Category } from 'models'
import ArticleCard from 'components/ArticleCard/ArticleCard'
import { useQuery } from '@apollo/client'
import { INSIGHT_QUERY } from 'gql/queries/contentBuckets'
import LoadingMessage from 'components/LoadingMessage'
import NoSearchResultsMessage from 'components/NoSearchResultsMessage'

const CardGridContainer = styled(Grid)<GridProps>(() => ({
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    }
  }
}))

// TODO: This is a static page for now until we determine if we need a category for this
const CommunityInsightsPage = () => {
  const { data, loading } = useQuery(INSIGHT_QUERY, {
    variables: { slug: 'community-insights-report' }
  })
  const category: Category = {
    slug: 'community-insights'
  }
  if (loading) return <LoadingMessage />
  const { communityInsight } = data

  return (
    <Box sx={{ width: '100%' }}>
      <Grid paddingBottom='42px' container>
        <Grid item sm={12} xl={6}>
          <Typography variant='h1'>Community Insights</Typography>
        </Grid>
      </Grid>
      {loading ? (
        <LoadingMessage />
      ) : (
        <Grid container rowSpacing={3} columnSpacing={3}>
          {communityInsight ? (
            <>
              <CardGridContainer item sm={12} xl={6}>
                <ArticleCard
                  articleVariant='content'
                  url='/community-insights/community-insights-report'
                  title={communityInsight.title}
                  blurb={communityInsight.blurb}
                  minutes={communityInsight.minutes}
                  startDate={communityInsight.startDate}
                  onDemand={communityInsight.onDemand}
                  contentBucket={communityInsight.contentBucket}
                  category={category}
                  tags={communityInsight.tags}
                />
              </CardGridContainer>
            </>
          ) : (
            <NoSearchResultsMessage isCategoryPage />
          )}
        </Grid>
      )}
    </Box>
  )
}

export default CommunityInsightsPage
