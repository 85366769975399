import React from 'react'
import { DownloadIcon } from '../Icons'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Box } from '@mui/material'

interface DownloadButtonProps {
  contentLabel: string
  pdfUrl?: string
  childPdfUrl?: string
  videoUrl?: string
}

type DownloadOption = {
  label: string
  url?: string
}

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: '#F1F1F1',
  color: 'black',
  '& svg': {
    fontSize: '16px'
  },
  marginLeft: '16px'
}))

const StyledMenu = styled(Menu)<MenuProps>(() => ({
  a: {
    color: 'black',
    textDecoration: 'none'
  },
  '& .MuiMenu-paper': {
    backgroundColor: 'black',
    color: 'white',
    '& .MuiList-root': {
      minWidth: '245px'
    },
    '& .MuiMenuItem-root': {
      fontSize: '1rem',
      '&:hover': {
        fontWeight: 700
      }
    }
  }
}))

const constructMenuList = (
  contentLabel: string,
  url?: string,
  childUrl?: string
): DownloadOption[] => {
  switch (contentLabel) {
    case 'HOW TO':
      return [{ label: 'Download PDF', url: url }]
    case 'TOOLKIT':
      return [
        { label: 'Download this slide', url: childUrl },
        { label: 'Download the entire PDF', url: url }
      ]
    case 'BOOK':
      return [{ label: 'Download Chapter PDF', url: url }]
    case 'COMMUNITY INSIGHT':
      return [{ label: 'Download Report PDF', url: url }]
    default:
      return []
  }
}

const DownloadButton = ({
  contentLabel,
  pdfUrl,
  childPdfUrl,
  videoUrl
}: DownloadButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const showDownload: boolean = pdfUrl !== undefined || videoUrl !== undefined
  const menuOptions: DownloadOption[] = showDownload
    ? constructMenuList(contentLabel, pdfUrl || videoUrl, childPdfUrl)
    : []

  return (
    <Box>
      {showDownload && menuOptions && menuOptions.length > 0 ? (
        <>
          <StyledIconButton onClick={handleClick} aria-label='download'>
            <DownloadIcon />
          </StyledIconButton>
          <StyledMenu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'icon-button'
            }}
          >
            {menuOptions.map((menuOption: DownloadOption, index: number) => (
              <a
                key={`option-${index + 1}`}
                href={menuOption.url}
                target='_blank'
                rel='noreferrer'
                download
              >
                <MenuItem onClick={handleClose}>{menuOption.label}</MenuItem>
              </a>
            ))}
          </StyledMenu>
        </>
      ) : null}
    </Box>
  )
}
export default DownloadButton
