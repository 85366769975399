import { ResponsiveImageType } from 'react-datocms'
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils'

export enum ContentBucketType {
  'book' = 'BOOK',
  'case-study' = 'CASE_STUDY',
  'event' = 'EVENT',
  'video' = 'VIDEO',
  'toolkit' = 'TOOLKIT',
  'interview' = 'INTERVIEW',
  'how-to' = 'HOW_TO',
  'community-insight' = 'COMMUNITY_INSIGHT',
  'thought-leadership' = 'THOUGHT_LEADERSHIP'
}

export enum ContentLabel {
  'event' = 'EVENT',
  'book' = 'BOOK',
  'case-study' = 'CASE STUDY',
  'video' = 'VIDEO',
  'toolkit' = 'TOOLKIT',
  'interview' = 'INTERVIEW',
  'how-to' = 'HOW TO',
  'community-insight' = 'COMMUNITY INSIGHT',
  'thought-leadership' = 'THOUGHT LEADERSHIP'
}

export enum Colors {
  'EVENT' = '#AACDF4',
  'BOOK' = 'rgba(128, 11, 138, 0.5)',
  'CASE_STUDY' = '#D6DF6B',
  'INTERVIEW' = 'rgba(255, 213, 0, 0.9)',
  'VIDEO' = 'rgba(78, 41, 226, 0.6)',
  'TOOLKIT' = 'rgba(21, 161, 145, 0.5)',
  'HOW_TO' = 'rgba(244, 58, 47, 0.5)',
  'COMMUNITY_INSIGHT' = '#EBF2A1',
  'THOUGHT_LEADERSHIP' = '#F2D9EF',
  'DEFAULT' = '#CCCCCC'
}

export type Photo = {
  url: string
}

export type Author = {
  firstName: string
  lastName: string
  photo: Photo
  bio: string
}

export type ContentBucket = {
  id?: string
  slug: string
}

export type Category = {
  slug: string
}

export interface CategoryData extends Category {
  __typename: string
  id: string
  name: string
  slug: string
  blurb: string
  featuredArticles: CmsArticle[]
}

export type CmsImage = {
  responsiveImage: ResponsiveImageType
  fullSizeImage?: boolean
}

export type CmsPdf = {
  url: string
  alt?: string
}

export type BaseArticle = {
  title: string
  blurb: string
  contentBucket: ContentBucket
  category: Category
  startDate?: string
  onDemand?: boolean | null | undefined
  image?: CmsImage
  minutes?: number
  tags?: string[] | null
}

export interface CmsArticle extends BaseArticle {
  slug: string
  pdf?: CmsPdf
  externalUrl?: string
  __typename?: string
}

export interface Chapter extends BaseArticle {
  slug: string
  chapterNumber: string
}

export type CategoryBuckets = {
  allChapters: CmsArticle[]
  allEvents: CmsArticle[]
  allHowTos: CmsArticle[]
  allInterviews: CmsArticle[]
  allToolkits: CmsArticle[]
  allVideos: CmsArticle[]
}

export type CmsListItem = {
  listItemContent: StructuredTextType
}

export interface SlideContainerProps {
  title: string
  label: string
  bgColor: string
  time: number
  content?: StructuredTextType
  slides?: ToolkitSlide[]
  currentSlide?: ToolkitSlide
  pdfUrl?: string
  baseToolkitUrl?: string
  categoryPageUrl?: string
}

export interface ChapterContentType extends StructuredTextType {
  blocks: never[]
  value: {
    schema: any
    document: {
      type: any
      children: never[]
    }
    __typename: string
  }
}

export interface ArticleContainerProps {
  title: string
  label: string
  bgColor: string
  content: ChapterContentType
  time?: number
  dateTime?: string
  isVideo?: boolean
  pdfUrl?: string
  chapterTitle?: string
  chapterNumber?: string
  sources?: SourceType[]
}

export type SourceType = {
  id: string
  link: any
}

export type TranscriptType = {
  minuteMark: number
  secondsMark: number
  copy: StructuredTextType
}

export type Slide = {
  title: string
  slug: string
  slideNumber: number
}

export interface ToolkitSlide extends Slide {
  id: string
  eyebrow: string
  pdf: CmsPdf
  content: StructuredTextType
}

export interface Toolkit extends CmsArticle {
  slides: ToolkitSlide[]
}

export type Navigation = {
  label: string
  link: string
  id?: string
  hide?: boolean
}

export type SearchResultsType = {
  blurb: string
  category: string
  contentType: string
  external_id: string
  highlight: any
  audience?: string[] | null
  id: string
  sort: any
  title: string
  updated_at: string
  url: string
  minutes?: string | null
  tags?: string[] | null
  _explanation: any
  _index: string
  _score: number
  _type: string
  _version: any
}
