/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { ContentLabel } from 'models'
import { getChipColorByContentBucketSlug } from 'utils/hooks'
import SlideContainer from 'components/Layout/SlideContainer/SlideContainer'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { HOW_TO_QUERY } from '../../gql/queries/contentBuckets'

const HowToContent = () => {
  const { slug } = useParams()
  const { data, loading } = useQuery(HOW_TO_QUERY, { variables: { slug } })

  if (loading) return <></>

  const { howTo } = data
  const label =
    ContentLabel[howTo.contentBucket.slug as keyof typeof ContentLabel]
  const bgColor = getChipColorByContentBucketSlug(howTo.contentBucket.slug)

  return (
    <Box sx={{ width: '100%' }}>
      <SlideContainer
        title={howTo.title}
        label={label}
        bgColor={bgColor}
        time={howTo.minutes}
        content={howTo.content}
        pdfUrl={howTo.pdf ? howTo.pdf.url : undefined}
      />
    </Box>
  )
}

export default HowToContent
