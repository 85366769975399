import React from 'react'
import { StructuredText } from 'react-datocms'
import { Box, Typography, BoxProps } from '@mui/material'
import { SourceType } from 'models'
import { styled } from '@mui/material/styles'
import { Color } from '@mathison-inc/components'

interface Props {
  sources: SourceType[]
}

const SourcesContainer = styled(Box)<BoxProps>(() => ({
  a: {
    color: Color.black
  },
  p: { display: 'inline' }
}))

const ChapterSources = ({ sources }: Props) => {
  return (
    <SourcesContainer>
      <Typography variant='h5' sx={{ marginBottom: '16px' }}>
        Sources
      </Typography>
      {sources.map((source: SourceType) => (
        <Box key={source.id} sx={{ marginBottom: '16px' }}>
          <StructuredText data={source.link} />
        </Box>
      ))}
    </SourcesContainer>
  )
}

export default ChapterSources
