import { gql } from '@apollo/client'

export const CATEGORIES_QUERY = gql`
  query Categories {
    allCategories {
      slug
    }
  }
`

export const CATEGORY_QUERY = gql`
  query CategoryPage($slug: String) {
    category(filter: { slug: { eq: $slug } }) {
      id
      name
      blurb
      slug
    }
  }
`

export const CATEGORY_CONTENT_QUERY = gql`
  query CategoryContent($categoryId: ItemId, $audienceId: [ItemId]) {
    allChapters(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allEvents(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
      orderBy: [onDemand_ASC]
    ) {
      title
      onDemand
      blurb
      slug
      externalUrl
      startDate
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allHowTos(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allInterviews(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allToolkits(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allVideos(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
    allThoughtLeaderships(
      filter: {
        category: { eq: $categoryId }
        audience: { allIn: $audienceId }
      }
    ) {
      title
      blurb
      slug
      minutes
      tags
      contentBucket {
        slug
      }
      category {
        slug
      }
      audience {
        name
      }
    }
  }
`
