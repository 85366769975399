import React from 'react'
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import ChapterChip from '../ChapterChip/ChapterChip'

interface IProps {
  bookmark?: () => void
  chapterNumber: number
}

const StyledStack = styled(Stack)<StackProps>(() => ({
  paddingBottom: '40px'
}))

export const ChapterCardEyebrow = (props: IProps) => {
  const { chapterNumber } = props

  return (
    <StyledStack direction='row'>
      <ChapterChip chapterNumber={chapterNumber} />
    </StyledStack>
  )
}

export default ChapterCardEyebrow
