import React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { constructEyebrowTimeLabel } from '../../utils/hooks'

interface TimeLabelChipProps {
  time?: number
  onPage: boolean
  isVideo?: boolean
  label?: string
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '2px',
  color: '#000000',
  backgroundColor: 'transparent',
  textTransform: 'uppercase',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    padding: '0 16px 0 16px'
  }
}))

const ReadViewTimeChip = (props: TimeLabelChipProps) => {
  const { time, onPage, isVideo, label } = props
  const chipLabel: string | undefined | 0 =
    time && constructEyebrowTimeLabel(time, onPage, isVideo)
  const isReadEvent =
    label !== 'VIDEO' && label !== 'EVENT' && label !== undefined
  return <StyledChip label={isReadEvent ? `${chipLabel}ute Read` : chipLabel} />
}
export default ReadViewTimeChip
