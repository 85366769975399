import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const BlueBlobIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon className='blob blue-blob' {...props}>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity='0.4'
      >
        <g id='Susanna_Vector' fill='#0079B6'>
          <path
            d='M24.750979,14.9683908 C24.0103151,18.326748 21.6038804,20.8800223 18.5786093,22.7908093 C14.6123055,25.295761 10.1015379,28.9129847 5.64605206,27.0345261 C1.1435997,25.1361601 0.501913846,19.5363459 0.0553610397,14.981067 C-0.301060809,11.3451949 1.08848464,7.99092136 3.50384368,5.18175812 C5.90199203,2.39260455 9.05428022,-0.0611254173 12.9352708,0.00116062564 C16.7998929,0.0631832761 20.077803,2.54961918 22.3950789,5.48493179 C24.5796377,8.252153 25.4809235,11.6584414 24.750979,14.9683908 Z'
            id='Path'
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default BlueBlobIcon
