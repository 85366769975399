import { gql } from '@apollo/client'
import {
  chapterRecordFragment,
  eventRecordFragment,
  howToRecordFragment,
  interviewRecordFragment,
  toolkitRecordFragment,
  videoRecordFragment,
  responsiveImageFragment
} from '../fragments'

export const LANDING_PAGE_QUERY = gql`
  query Home {
    landingPage {
      title
      featured {
        __typename
        ... on ChapterRecord {
          ...chapterRecordFragment
        }
        ... on EventRecord {
          ...eventRecordFragment
        }
        ... on HowToRecord {
          ...howToRecordFragment
        }
        ... on InterviewRecord {
          ...interviewRecordFragment
        }
        ... on ToolkitRecord {
          ...toolkitRecordFragment
        }
        ... on VideoRecord {
          ...videoRecordFragment
        }
      }
      fallbackImage {
        responsiveImage(imgixParams: { fm: jpg }) {
          ...responsiveImageFragment
        }
      }
      newlyAdded {
        __typename
        ... on ChapterRecord {
          ...chapterRecordFragment
        }
        ... on EventRecord {
          ...eventRecordFragment
        }
        ... on HowToRecord {
          ...howToRecordFragment
        }
        ... on InterviewRecord {
          ...interviewRecordFragment
        }
        ... on ToolkitRecord {
          ...toolkitRecordFragment
        }
        ... on VideoRecord {
          ...videoRecordFragment
        }
      }
      recommended {
        __typename
        ... on ChapterRecord {
          ...chapterRecordFragment
        }
        ... on EventRecord {
          ...eventRecordFragment
        }
        ... on HowToRecord {
          ...howToRecordFragment
        }
        ... on InterviewRecord {
          ...interviewRecordFragment
        }
        ... on ToolkitRecord {
          ...toolkitRecordFragment
        }
        ... on VideoRecord {
          ...videoRecordFragment
        }
      }
    }
  }
  ${chapterRecordFragment}
  ${eventRecordFragment}
  ${howToRecordFragment}
  ${interviewRecordFragment}
  ${toolkitRecordFragment}
  ${videoRecordFragment}
  ${responsiveImageFragment}
`
