import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  TextField,
  Box,
  Divider,
  Typography,
  BoxProps
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Color } from 'components'
import { styled } from '@mui/material/styles'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ArticleCard from 'components/ArticleCard/ArticleCard'
import { SearchResultsType } from 'models'
import { search } from 'utils/searchUtils'

interface SearchButtonProps extends ButtonProps {
  openSearchbar: boolean
}

const SearchButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'openSearchbar'
})<SearchButtonProps>(({ openSearchbar }) => ({
  borderRadius: '100%',
  backgroundColor: !openSearchbar ? Color.grey50 : 'none',
  border: !openSearchbar ? `1px solid ${Color.grey300}` : 'none',
  padding: '13px 13px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: Color.grey50
  }
}))

interface SearchbarContainerProps extends PaperProps {
  isOpen?: boolean
}

const SearchbarContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isOpen'
})<SearchbarContainerProps>(({ isOpen }) => ({
  display: 'flex',
  width: isOpen ? '100%' : '480px',
  height: '64px',
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '100px',
  backgroundColor: Color.grey50,
  border: `1px solid ${Color.grey300}`,
  margin: isOpen ? '32px 0 24px' : 0
}))

const CancelButton = styled(Button)<ButtonProps>(() => ({
  padding: 0,
  maxWidth: '21px',
  '&:hover': {
    pointer: 'cursor',
    backgroundColor: 'transparent'
  }
}))

interface SearchSuggestionsContainerProps extends BoxProps {
  isOpen?: boolean
  searchSuggestions: SearchResultsType[]
}

const SearchSuggestionsContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isOpen' && prop !== 'searchSuggestions'
})<SearchSuggestionsContainerProps>(({ isOpen, searchSuggestions }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '32px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)',
  zIndex: 10,
  top: isOpen ? 187 : 130,
  backgroundColor: Color.grey50,
  width: isOpen ? '76%' : '480px',
  paddingTop: searchSuggestions.length === 0 ? '0px' : '16px'
}))

interface Props {
  isOpen?: boolean
  searchParam?: string
}

const Searchbar = ({ isOpen, searchParam }: Props) => {
  const [openSearchbar, setOpenSearchbar] = useState(isOpen || false)
  const [searchInput, setSearchInput] = useState(searchParam || '')
  const [searchSuggestions, setSearchSuggestions] = useState<
    SearchResultsType[]
  >([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [focus, setFocus] = useState(false)
  const navigate = useNavigate()
  const ref = useRef()
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (focus) {
      //@ts-ignore
      inputRef.current.focus()
      setFocus(false)
    }
  }, [focus])

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      //@ts-ignore
      if (showSuggestions && ref.current && !ref.current.contains(e.target)) {
        setShowSuggestions(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showSuggestions])

  useEffect(() => {
    if (!searchInput) return
    setLoading(true)
    search(searchInput, setSearchSuggestions, setLoading)
  }, [searchInput])

  return (
    <>
      {!openSearchbar ? (
        <SearchButton
          disableRipple
          openSearchbar={openSearchbar}
          onClick={() => {
            setOpenSearchbar(true)
            setFocus(true)
          }}
        >
          <SearchIcon sx={{ fontSize: '36px' }} />
        </SearchButton>
      ) : (
        <SearchbarContainer isOpen={isOpen}>
          <SearchButton
            disableRipple
            openSearchbar={openSearchbar}
            onClick={() => {
              if (searchInput)
                navigate(`/knowledge-center/search/${searchInput}`)
            }}
          >
            <SearchIcon sx={{ fontSize: '36px' }} />
          </SearchButton>
          <TextField
            inputRef={inputRef}
            value={searchInput}
            variant='standard'
            InputProps={{
              disableUnderline: true
            }}
            onChange={e => {
              setSearchInput(e.target.value)
              setShowSuggestions(true)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && searchInput) {
                navigate(`/knowledge-center/search/${searchInput}`)
                setShowSuggestions(false)
              }
            }}
            onFocus={() => setShowSuggestions(true)}
            sx={{
              width: '100%',
              '& input': {
                fontFamily: 'IBM Plex Sans',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '28px'
              }
            }}
          />
          {((isOpen && searchInput) || !isOpen) && (
            <CancelButton
              disableRipple
              onClick={() => {
                setSearchInput('')
                if (!searchParam) setOpenSearchbar(false)
                if (isOpen) setFocus(true)
              }}
            >
              <ClearRoundedIcon
                sx={{
                  fontSize: '21px'
                }}
              />
            </CancelButton>
          )}
          {searchInput && showSuggestions && (
            <SearchSuggestionsContainer
              searchSuggestions={searchSuggestions}
              isOpen={isOpen}
              ref={ref}
            >
              {searchSuggestions.length > 0 &&
                searchSuggestions.slice(0, 5).map((suggestion, index) => (
                  <Box key={index}>
                    <ArticleCard
                      url={suggestion.url}
                      articleVariant={'searchSuggestion'}
                      title={suggestion.title}
                      blurb={suggestion.blurb}
                      category={{ slug: suggestion.category }}
                      contentBucket={{ slug: suggestion.contentType }}
                      isSearchResultsCard={true}
                      minutes={Number(suggestion.minutes)}
                      searchInput={searchInput}
                    />
                    <Divider
                      sx={{ width: isOpen ? '97%' : '93%', margin: 'auto' }}
                    />
                  </Box>
                ))}
              {!loading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '38px 32px'
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <SearchIcon
                      sx={{ marginRight: '12px', fontSize: '20px' }}
                    />
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {searchSuggestions.length === 1
                        ? `All results for "${searchInput}" (${searchSuggestions.length} result)`
                        : `All results for "${searchInput}" (${searchSuggestions.length} results)`}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: '14px', color: Color.grey600 }}>
                    Press Enter
                  </Typography>
                </Box>
              )}
            </SearchSuggestionsContainer>
          )}
        </SearchbarContainer>
      )}
    </>
  )
}

export default Searchbar
