import { gql } from '@apollo/client'

export const BOOK_QUERY = gql`
  query BookPage {
    hiringForDiversity {
      title
      blurb
      authors {
        firstName
        lastName
        bio
        photo {
          url
        }
      }
    }
    allChapters {
      slug
      chapterNumber
      title
      blurb
      minutes
      contentBucket {
        slug
      }
      category {
        slug
      }
    }
  }
`
