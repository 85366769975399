import React from 'react'
import { Box, Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

interface NavButtonProps extends ButtonProps {
  direction: 'next' | 'previous'
}

const NavButton = styled(Button, {
  shouldForwardProp: props => props !== 'direction'
})<NavButtonProps>(({ direction }) => ({
  padding: '12px 16px',
  color: direction === 'next' ? 'white' : 'black',
  backgroundColor: direction === 'next' ? 'black' : '#F2F3F3',
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  marginLeft: direction === 'previous' ? '16px' : 0,
  '&:hover': {
    color: direction === 'next' ? 'white' : 'black',
    backgroundColor: direction === 'next' ? 'black' : '#F2F3F3'
  },
  '&:disabled': {
    color: direction === 'next' ? 'grey' : 'default'
  }
}))

interface Props {
  chapterNumber: string
}

const ChapterNavigation = ({ chapterNumber }: Props) => {
  const navigate = useNavigate()
  const onClick = (direction: string) => {
    if (direction === 'next') {
      navigate(
        `/knowledge-center/hiring-for-diversity/chapter/${
          Number(chapterNumber) + 1
        }`
      )
    } else {
      navigate(
        `/knowledge-center/hiring-for-diversity/chapter/${
          Number(chapterNumber) - 1
        }`
      )
    }
  }
  return (
    <Box sx={{ margin: '72px 0 80px 0' }}>
      <NavButton
        disabled={Number(chapterNumber) + 1 === 12}
        disableRipple
        direction='next'
        onClick={() => onClick('next')}
      >
        Next Chapter
      </NavButton>
      <NavButton
        disabled={Number(chapterNumber) - 1 === 0}
        disableRipple
        direction='previous'
        onClick={() => onClick('previous')}
      >
        Previous Chapter
      </NavButton>
    </Box>
  )
}

export default ChapterNavigation
