import React from 'react'
import { Box, Typography } from '@mui/material'
import Grid, { GridProps } from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/material/Box'
import { BOOK_QUERY } from 'gql/queries/book'
import { useQuery } from '@apollo/client'
import { getLinkUrl } from '../../utils/hooks'
import Authors from '../../components/Authors/Authors'
import { Chapter } from '../../models'
import BookCTA from '../../components/BookCTA/BookCTA'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import { theme } from '../../components'

const BlurbContainer = styled(Box)<BoxProps>(() => ({
  paddingBottom: 40
}))

const CardGridContainer = styled(Grid)<GridProps>(() => ({
  '& .article-card': {
    [theme.breakpoints.up('xs')]: {
      height: '100%'
    }
  }
}))

const HiringForDiversity = () => {
  const { data, loading } = useQuery(BOOK_QUERY)

  if (loading) return null

  const { hiringForDiversity, allChapters } = data
  const chaptersForSort = [...allChapters]
  const chapters = chaptersForSort.sort((a: Chapter, b: Chapter) =>
    parseInt(a.chapterNumber, 10) > parseInt(b.chapterNumber, 10) ? 1 : -1
  )

  return (
    <Box
      sx={{
        boxSizing: 'border-box'
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={6.7}>
          <Typography variant='h1' sx={{ marginBottom: '24px' }}>
            {hiringForDiversity.title}
          </Typography>
          <BlurbContainer>
            <Typography
              variant='body1'
              sx={{
                fontSize: '18px',
                lineHeight: '24px',
                fontWeight: 300,
                width: '85%'
              }}
            >
              {hiringForDiversity.blurb}
            </Typography>
          </BlurbContainer>
          <Authors authors={hiringForDiversity.authors} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5.3}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <BookCTA />
        </Grid>
      </Grid>
      <Grid container rowSpacing={6} columnSpacing={3}>
        {chapters && chapters.length > 0 ? (
          <>
            {chapters.map((chapter: Chapter, index: number) => (
              <CardGridContainer
                key={`chapter-card-${index}`}
                item
                md={12}
                xl={4}
              >
                <ArticleCard
                  articleVariant='chapter'
                  url={getLinkUrl(chapter.slug, chapter.contentBucket)}
                  title={chapter.title}
                  blurb={chapter.blurb}
                  minutes={chapter.minutes}
                  contentBucket={chapter.contentBucket}
                  category={chapter.category}
                  chapterNumber={parseInt(chapter.chapterNumber, 10)}
                />
              </CardGridContainer>
            ))}
          </>
        ) : null}
      </Grid>
    </Box>
  )
}

export default HiringForDiversity
