/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-named-default */
import React from 'react'
import { default as _ReactPlayer } from 'react-player'
import { ReactPlayerProps } from 'react-player/types/lib'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

interface KCVideoProps {
  videoUrl: string
  image?: boolean | string
  playing?: boolean
  height?: string
  marginTop?: string
}

type PlayerWrapperProps = {
  height?: string
  marginTop?: string
}

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

const PlayerWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'height' && prop !== 'marginTop'
})<PlayerWrapperProps>(({ height, marginTop }) => ({
  position: 'relative',
  width: '100%',
  height: height || '600px',
  marginTop: marginTop || '0',
  '& .react-player': {
    '& .react-player__shadow': {
      backgroundColor: 'black !important',
      borderRadius: '8px !important',
      width: '85px !important',
      height: '85px !important'
    },
    video: {
      backgroundColor: 'black'
    }
  }
}))

const VideoPlayer = ({
  videoUrl,
  image,
  playing,
  height,
  marginTop
}: KCVideoProps) => {
  const isMp4 = videoUrl.includes('.mp4')
  const videoUrlArray = videoUrl.split('?v=')
  const videoID = videoUrlArray[1]

  return (
    <PlayerWrapper height={height} marginTop={marginTop}>
      {isMp4 ? (
        <ReactPlayer
          className='react-player'
          url={videoUrl}
          width='100%'
          height='100%'
          controls
          light={image}
          playing={playing}
          config={{
            file: {
              attributes: {
                onContextMenu: (e: Event) => e.preventDefault()
              }
            }
          }}
        />
      ) : (
        <Box sx={{ width: '100%', height: '100%' }}>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/${videoID}`}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='YouTube Changeforce Video'
          />
        </Box>
      )}
    </PlayerWrapper>
  )
}
export default VideoPlayer
