/* eslint-disable react/require-default-props */
import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { ResponsiveImageType } from 'react-datocms'
import { styled } from '@mui/material/styles'
import KCModal from '../KCModal/KCModal'
import VideoPlayer from '../VideoPlayer/VideoPlayer'

interface VideoModalProps {
  videoUrl: string
  image: ResponsiveImageType
  heroTitle?: string | undefined
  heroSubtitle?: string | undefined
}

const VideoModalContainer = styled(Box)<BoxProps>(() => ({
  '& .modal-btn-wrapper': {
    position: 'relative',
    width: '100%',
    marginTop: '-54px'
  },
  '&:hover': {
    '& .modal-btn-wrapper button': {
      backgroundColor: 'black'
    }
  }
}))

const VideoModal = ({
  videoUrl,
  image,
  heroTitle,
  heroSubtitle
}: VideoModalProps) => {
  return (
    <VideoModalContainer>
      <KCModal
        type='video'
        image={image}
        heroTitle={heroTitle}
        heroSubtitle={heroSubtitle}
      >
        <VideoPlayer
          videoUrl={videoUrl}
          image={false}
          playing
          height='81%'
          marginTop='-10%'
        />
      </KCModal>
    </VideoModalContainer>
  )
}
export default VideoModal
