import React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/system/Box'
import { styled } from '@mui/material/styles'
import { Grid, GridSize } from '@mui/material'
import { CmsListItem } from '../../models'
import StructuredTextContainer from '../Layout/StructuredTextContainer/StructuredTextContainer'

interface ListComponentProps {
  title: string
  subtitle?: string
  checklist: boolean
  item: CmsListItem[]
  isSlide: boolean
}

interface ListContainerProps extends BoxProps {
  isSlide: boolean
}

const ListContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isSlide'
})<ListContainerProps>(({ isSlide }) => ({
  ...(isSlide && {
    margin: '40px 12.5% 40px 12.5%'
  }),
  ul: {
    paddingInlineStart: 0,
    '& .MuiGrid-item': {
      paddingTop: 0
    }
  }
}))

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  marginTop: 0,
  color: 'black',
  cursor: 'default',
  backgroundColor: '#F2F2F2',
  '&:hover': {
    backgroundColor: '#F2F2F2'
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}))

const StyledNumberContainer = styled(Box)<BoxProps>(() => ({
  marginTop: '4px',
  color: 'black',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 700,
  fontFamily: 'IBM Plex Sans, sans-serif',
  letterSpacing: '0.14em'
}))

const ListContentContainer = styled(Box)<BoxProps>(() => ({
  color: 'black',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  p: {
    margin: '16px 0',
    letterSpacing: '-1px',
    fontSize: '16px',
    lineHeight: '24px',
    strong: {
      letterSpacing: '-0.02em'
    }
  }
}))

const ListComponent = ({
  title,
  subtitle,
  checklist,
  item,
  isSlide
}: ListComponentProps) => {
  const gridSize: GridSize = checklist ? 6 : 12

  return (
    <ListContainer isSlide={isSlide}>
      <Typography
        variant='h6'
        sx={{
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '0.17em',
          paddingBottom: '24px'
        }}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}>
          {subtitle}
        </Typography>
      ) : null}
      {item && item.length > 0 ? (
        <List
          sx={{
            width: '100%',
            background: 'transparent',
            padding: '28px 0 0'
          }}
        >
          <Grid container component='li' rowSpacing={3} columnSpacing={3}>
            {item.map((listItem: CmsListItem, index: number) => (
              <Grid
                key={`list-item-${index + 1}`}
                item
                sx={{ listStyle: 'none' }}
                component='ul'
                sm={12}
                xl={gridSize}
              >
                <Divider
                  sx={{
                    borderColor: '#ACADAF',
                    opacity: '90%',
                    paddingBottom: '16px'
                  }}
                />

                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '70px',
                      alignSelf: 'flex-start',
                      paddingTop: '5px'
                    }}
                  >
                    <React.Fragment>
                      {checklist ? (
                        <StyledIconButton aria-label='delete' size='small'>
                          <CheckIcon />
                        </StyledIconButton>
                      ) : (
                        <StyledNumberContainer>
                          {index < 9 ? `0${index + 1}` : `${index + 1}`}
                        </StyledNumberContainer>
                      )}
                    </React.Fragment>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <ListContentContainer>
                        <StructuredTextContainer
                          content={listItem.listItemContent}
                        />
                      </ListContentContainer>
                    }
                  />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </List>
      ) : null}
    </ListContainer>
  )
}
export default ListComponent
