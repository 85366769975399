import { useMemo } from 'react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const Client = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: 'https://graphql.datocms.com/'
    })

    const authLink = setContext((_, { headers }) => {
      const token = process.env.REACT_APP_CMS_API_TOKEN

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    })
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })
  }, [])
}

export default Client
