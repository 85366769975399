import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Typography, Box } from '@mui/material'
import Searchbar from 'components/Searchbar'
import Filter from 'pages/Category/Filter'
import ArticleCard from 'components/ArticleCard/ArticleCard'
import LoadingMessage from 'components/LoadingMessage'
import NoSearchResultsMessage from 'components/NoSearchResultsMessage'
import { SearchResultsType } from 'models'
import { search } from 'utils/searchUtils'
import {
  FormatFilterValues,
  IntendedForFilterValues,
  CategoryFilterValues
} from '../Category/constants'

const SearchResultsPage = () => {
  const { searchInput } = useParams()
  const [searchResults, setSearchResults] = useState<SearchResultsType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const [formatFilter, setFormatFilter] = useState<string[]>([])
  const [intendedForFilter, setIntendedForFilter] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!searchInput) return
    setLoading(true)
    search(searchInput, setSearchResults, setLoading)
  }, [searchInput])

  const filterByCategory = (res: any) => {
    let category = ''
    switch (res.category) {
      case 'defining-tracking-dei':
        category = 'Defining & Tracking'
        break
      case 'sourcing-attracting':
        category = 'Attracting & Sourcing'
        break
      case 'interviewing-engaging':
        category = 'Interviewing & Engaging'
        break
      case 'onboarding-advancing':
        category = 'Onboarding & Advancing'
        break
      default:
        category = res.category
    }
    return categoryFilter.find(filter => category === filter) !== undefined
  }

  const filterByFormat = (res: any) => {
    return (
      formatFilter.find(
        filter => res.contentType === filter.replace(' ', '-').toLowerCase()
      ) !== undefined
    )
  }

  const filterByAudience = (res: any) => {
    const splitAudience = res.audience && res.audience[0].split(',')
    return (
      intendedForFilter.find(filter => splitAudience?.includes(filter)) !==
      undefined
    )
  }

  const filteredSearchResults = searchResults.filter(res => {
    if (
      !categoryFilter.length &&
      !formatFilter.length &&
      !intendedForFilter.length
    ) {
      return true
    }
    if (
      categoryFilter.length &&
      formatFilter.length &&
      intendedForFilter.length
    ) {
      return (
        filterByCategory(res) && filterByFormat(res) && filterByAudience(res)
      )
    }
    if (
      categoryFilter.length &&
      formatFilter.length &&
      !intendedForFilter.length
    ) {
      return filterByCategory(res) && filterByFormat(res)
    }
    if (
      categoryFilter.length &&
      !formatFilter.length &&
      intendedForFilter.length
    ) {
      return filterByCategory(res) && filterByAudience(res)
    }
    if (
      !categoryFilter.length &&
      formatFilter.length &&
      intendedForFilter.length
    ) {
      return filterByFormat(res) && filterByAudience(res)
    }
    return filterByCategory(res) || filterByFormat(res) || filterByAudience(res)
  })

  return (
    <Box
      sx={{
        height: '100%',
        maxWidth: '812px'
      }}
    >
      <Typography
        variant='h7'
        sx={{
          textTransform: 'uppercase',
          fontSize: '14px',
          letterSpacing: '0.14em'
        }}
      >
        {filteredSearchResults.length === 1
          ? `Showing ${filteredSearchResults.length} Result For:`
          : `Showing ${filteredSearchResults.length} Results For:`}
      </Typography>
      <Searchbar isOpen searchParam={searchInput} />
      <Grid
        container
        columnSpacing={3}
        sx={{
          marginBottom: '40px',
          marginRight: 0
        }}
      >
        <Grid item xs={4}>
          <Filter
            name='Category'
            filterValues={CategoryFilterValues}
            selection={categoryFilter}
            setSelection={setCategoryFilter}
            isSearchResult
          />
        </Grid>
        <Grid item xs={4}>
          <Filter
            name='Format'
            filterValues={Object.keys(FormatFilterValues)}
            selection={formatFilter}
            setSelection={setFormatFilter}
            isSearchResult
          />
        </Grid>
        <Grid item xs={4}>
          <Filter
            name='Intended for'
            filterValues={IntendedForFilterValues}
            selection={intendedForFilter}
            setSelection={setIntendedForFilter}
            isSearchResult
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoadingMessage />
      ) : (
        <Grid container rowSpacing={3}>
          {filteredSearchResults && filteredSearchResults.length > 0 ? (
            <>
              {filteredSearchResults.map(
                (content: SearchResultsType, index: number) => (
                  <ArticleCard
                    key={index}
                    articleVariant='content'
                    title={content.title}
                    blurb={content.blurb}
                    minutes={Number(content.minutes)}
                    tags={content.tags}
                    url={content.url}
                    contentBucket={{
                      slug: content.contentType
                    }}
                    category={{ slug: content.category }}
                    isSearchResultsCard
                    categoryTitle={content.category}
                  />
                )
              )}
            </>
          ) : (
            <NoSearchResultsMessage />
          )}
        </Grid>
      )}
    </Box>
  )
}

export default SearchResultsPage
