import React, { FC } from 'react'
import {
  Button,
  ButtonProps,
  Menu,
  MenuProps,
  MenuItem,
  MenuItemProps
} from '@mui/material'
import { UpTriangleIcon, DownwardTriangleIcon } from '../Icons'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Slide } from 'models'

const StyledButton = styled(Button, {
  shouldForwardProp: prop =>
    prop !== 'currentSlide' && prop !== 'slides' && prop !== 'onSlideChoose'
})<SkipButtonProps>(() => ({
  fontFamily: '"IBM Plex Sans", sans-serif',
  fontWeight: 700,
  fontSize: 16,
  backgroundColor: '#F3F3F3',
  borderRadius: '8px',
  color: '#000000',
  padding: '18px 64px 18px 54px',
  textTransform: 'none',
  '& .MuiButton-endIcon': {
    marginLeft: 17
  },
  '&:hover': {
    backgroundColor: '#F8F8F8',
    color: '#000000'
  }
}))

const StyledMenu = styled(Menu)<MenuProps>(() => ({
  '& .MuiMenu-paper': {
    padding: '28px 40px',
    backgroundColor: '#000000',
    borderRadius: '8px !important'
  },
  '& .MuiList-root': {
    backgroundColor: '#000000'
  }
}))

const StyledMenuItem = styled(MenuItem)<MenuItemProps>(() => ({
  minWidth: 500,
  height: 32,
  '& .menuText': {
    paddingBottom: 0
  },
  '&:hover': {
    backgroundColor: 'inherit',
    '& .menuText': { fontWeight: 700 }
  }
}))

interface SkipButtonProps extends ButtonProps {
  slides?: Slide[]
  currentSlide: Slide
  onSlideChoose: (slug: string) => void
}

const SkipButton: FC<SkipButtonProps> = React.forwardRef(
  (props: SkipButtonProps, ref) => {
    const { slides, currentSlide, onSlideChoose } = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <StyledButton
          ref={ref}
          variant='contained'
          endIcon={
            open ? (
              <DownwardTriangleIcon style={{ fontSize: 14 }} />
            ) : (
              <UpTriangleIcon style={{ fontSize: 14 }} />
            )
          }
          {...props}
          disableElevation
          onClick={handleClick}
        />
        <StyledMenu
          id='select-slide-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          {slides!.length > 0 &&
            slides?.map((slide, index) => (
              <StyledMenuItem
                dense
                onClick={() => {
                  onSlideChoose(slide.slug)
                  handleClose()
                }}
                key={`menu_${index}`}
                disabled={currentSlide.slideNumber === slide.slideNumber}
              >
                <Typography
                  variant='body16Light'
                  color='#fff'
                  className='menuText'
                >
                  {slide.slideNumber.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}
                  &nbsp;&nbsp;&nbsp;
                  {slide.title}
                </Typography>
              </StyledMenuItem>
            ))}
        </StyledMenu>
      </>
    )
  }
)
SkipButton.displayName = 'SkipButton'

export default SkipButton
