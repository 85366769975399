/* eslint-disable react/require-default-props */
import React from 'react'
import Modal from '@mui/material/Modal'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Button, { ButtonProps } from '@mui/material/Button'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SearchIcon from '@mui/icons-material/Search'
import { ResponsiveImageType } from 'react-datocms'
import CoverImage from '../CoverImage'

interface KCModalProps {
  type?: 'video' | 'search'
  children?: React.ReactNode
  image?: ResponsiveImageType | undefined
  heroTitle?: string | undefined
  heroSubtitle?: string | undefined
}

const CoverContainer = styled(Box)<BoxProps>(() => ({
  '&:hover': {
    cursor: 'pointer',
    '& .video-button': {
      backgroundColor: '#444444',
      boxShadow: 'none'
    }
  }
}))

const ModalHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: '30px'
}))

const ModalContentContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  border: 'none'
}))

const PaperWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
  padding: '0 50px'
}))

const CloseButton = styled(IconButton)<IconButtonProps>(() => ({
  color: 'black',
  '& .hidden': {
    color: 'white'
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
}))

const PlayButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  color: 'white',
  backgroundColor: 'black',
  borderRadius: '8px',
  padding: '14px 16px 14px 24px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'black',
    boxShadow: 'none'
  }
}))

const SearchButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  color: 'black',
  padding: '10px 88px',
  borderColor: 'black',
  borderWidth: '2px',
  borderRight: 'none',
  borderLeft: 'none',
  borderTop: 'none',
  borderRadius: 0,
  '&:hover': {
    color: 'black',
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    borderRadius: 0,
    borderColor: 'black'
  }
}))

const KCModal = ({
  type,
  children,
  image,
  heroTitle,
  heroSubtitle
}: KCModalProps) => {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <CoverContainer>
      {image && (
        <CoverImage
          title={image.alt}
          responsiveImage={image}
          onClick={handleOpen}
          heroTitle={heroTitle}
          heroSubtitle={heroSubtitle}
        />
      )}
      <Box className='modal-btn-wrapper'>
        {type && type === 'video' ? (
          <PlayButton
            className='video-button'
            variant='contained'
            startIcon={
              <ArrowForwardIosIcon
                sx={{ marginRight: '10px', height: '14px', width: '14px' }}
              />
            }
            onClick={handleOpen}
          >
            Watch video
          </PlayButton>
        ) : (
          <SearchButton
            className='search-button'
            variant='outlined'
            startIcon={<SearchIcon />}
            onClick={handleOpen}
          >
            Search
          </SearchButton>
        )}
      </Box>

      <Modal
        open={open}
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalContentContainer>
          <ModalHeader>
            <CloseButton aria-label='close modal' onClick={handleClose}>
              <CloseIcon
                sx={{
                  fontSize: '28px',
                  color: '#395260',
                  marginBottom: '-100%',
                  marginRight: '10px'
                }}
              />
            </CloseButton>
          </ModalHeader>
          <PaperWrapper>{children}</PaperWrapper>
        </ModalContentContainer>
      </Modal>
    </CoverContainer>
  )
}
export default KCModal
