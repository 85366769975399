import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { constructEventTimeLabel } from '../../utils/hooks'

interface TimeChipProps {
  date: string
  onDemand: boolean | null | undefined
  cardGroup?: 'two' | 'three' | undefined
}

interface StyledChipProps extends ChipProps {
  cardGroup?: 'two' | 'three' | undefined
}

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== 'cardGroup'
})<StyledChipProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '2px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  textTransform: 'uppercase',
  padding: '0 15px',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    paddingLeft: 0
  }
}))

const DateTimeChip = ({ date, onDemand, cardGroup }: TimeChipProps) => {
  const label: string = constructEventTimeLabel(date)
  return (
    <>
      {onDemand ? (
        <StyledChip cardGroup={cardGroup} label={'On-demand webinar'} />
      ) : (
        <StyledChip cardGroup={cardGroup} label={label} />
      )}
    </>
  )
}
export default DateTimeChip
